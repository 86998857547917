/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "components/Loading";
import ConfirmedPayment from "components/reservation/confirmed_payment";
import WaitingPayment from "components/reservation/waiting_payment";
import { getTokenAuth } from "helper/localStorage";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { cancelPaymentV2 } from "service/payment";
import {
  createCounselingAnswer,
  getCounselingAnswer,
  getReservation,
} from "service/reservation";
import { getScheduleByReservation } from "service/schedule";

export default function Reservation() {
  const navigate = useNavigate();
  const params = useParams();
  const reservationCode = params.reservation_code as string;

  useEffect(() => {
    const token = getTokenAuth();
    if (!token) {
      navigate(`/booking/login?redirect_url=${window.location.pathname}`);
    }
  }, []);

  const queryReservation = useQuery(
    "getReservation",
    (): Promise<any> =>
      getReservation({ reservationCode: reservationCode }).then(
        (val) => val.data ?? {}
      )
  );
  const reservation = queryReservation.data;

  const queryCounselingAnswer = useQuery("getCounselingAnswer", () =>
    getCounselingAnswer(reservationCode).then((val: any) => val.data.answers)
  );
  const counselingAnswer = queryCounselingAnswer.data;

  if (queryReservation.isLoading) {
    return <Loading></Loading>;
  }

  const onCreateCounselingAnswer = async (data: any) => {
    await createCounselingAnswer(reservationCode, data);

    window.location.reload();
  };

  const onCancelPayment = async () => {
    const paymentCode = reservation.payment_gateaway_data.payment_code;

    await cancelPaymentV2({ paymentCode: paymentCode });

    navigate(`/booking`);
  };

  const onChangePayment = async () => {
    const reservationCode = reservation.reservation_code;
    const scheduleDetail = await getScheduleByReservation({
      reservationCode: reservationCode,
    }).then((val) => val.data);

    const paymentCode = reservation.payment_gateaway_data.payment_code;
    const psychologCode = reservation.psycholog_code;
    const appointmentType = reservation.format_call;
    const scheduleDetailCode = scheduleDetail.schedule_detail_code;

    await cancelPaymentV2({ paymentCode: paymentCode });

    navigate(
      `/booking/${psychologCode}/${scheduleDetailCode}?appointment_type=${appointmentType}&location=${reservation.location_detail.location_code}`
    );
  };

  const status = reservation.status;
  if (reservation.status === "waiting_payment") {
    return (
      <WaitingPayment
        reservation={reservation}
        onCancelPayment={onCancelPayment}
        onChangePayment={onChangePayment}
      ></WaitingPayment>
    );
  }

  if (
    ["confirmed", "rescheduled", "request_reschedule", "done"].includes(status)
  ) {
    return (
      <ConfirmedPayment
        reservation={reservation}
        onCreateCounselingAnswer={onCreateCounselingAnswer}
        counselingAnswer={counselingAnswer}
      ></ConfirmedPayment>
    );
  }

  return (
    <div className="text-center">reservation is not currently active...</div>
  );
}
