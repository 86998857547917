import Cookies from "js-cookie";
import { config } from "./config";

const OhanaToken = "ohana-token";
const OhanaUser = "ohana-user-data";
const OhanaUserName = "ohana_name_user";
const OhanaRole = "ohana_role";
const OhanaLastRefresh = "last_refresh";

//  User Name
export function clearNameCookies() {
  Cookies.remove(OhanaUserName, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function setNameCookies(name: string) {
  Cookies.set(OhanaUserName, name, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function getNameCookies() {
  return Cookies.get(OhanaUserName);
}

//  Role
export function clearRoleCookies() {
  Cookies.remove(OhanaRole, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function setRoleCookies(name: string) {
  Cookies.set(OhanaRole, name, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function getRoleCookies() {
  return Cookies.get(OhanaRole);
}

// Last Refresh
export function clearRefreshDate() {
  return localStorage.removeItem(OhanaLastRefresh);
}

// Last Refresh //
export function getLastRefreshDate() {
  return localStorage.getItem(OhanaLastRefresh);
}

export function setLastRefreshDate(val: string) {
  return localStorage.setItem(OhanaLastRefresh, val);
}

// User Data
export function clearUserData() {
  return localStorage.removeItem(OhanaUser);
}

// User Data //
export function setUserData(data: any) {
  return localStorage.setItem(OhanaUser, JSON.stringify(data));
}

export function getUserdata() {
  return JSON.parse(localStorage.getItem(OhanaUser) ?? "");
}

// TOKEN //
export function getTokenAuth() {
  return Cookies.get(OhanaToken);
}

export async function setTokenAuth(token: string) {
  Cookies.set(OhanaToken, token, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function clearTokenAuth() {
  // return localStorage.removeItem("ohana-token");
  Cookies.remove(OhanaToken, {
    domain: config.COOKIE_DOMAIN,
  });

  return Promise.all([
    // localStorage.removeItem(OhanaToken),
    clearNameCookies(),
    clearUserData(),
  ]);
}
