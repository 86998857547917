export const config = {
  AWS_URL_IMAGES: process.env.REACT_APP_GCS_URL || process.env.REACT_APP_AWS_URL,
  HOME_URL: `${process.env.REACT_APP_HOME_URL}`,
  WEB_URL: `${process.env.REACT_APP_WEB_URL}`,
  GOOGLE_CLIENT_ID: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  GOOGLE_API_KEY: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  playstoreLink:
    "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod",
  appstoreLink:
    "https://apps.apple.com/id/app/maxi-think-perform-better/id1632571529?itsct=apps_box_link&itscg=30200",
  constant: {
    signup: "signup",
    login: "login",
    loged: "loged",
  },
  useQuery: {
    fetchOnce: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
};
