import { Link } from "react-router-dom";
import { ButtonBorder, ButtonGold } from "./Button";
import Chip from "./Chip";
import { DividerVertical } from "./Divider";
import utils from "utils";
import { config } from "helper/config";

export function SpecialistItem(props: { value: any; className?: string }) {
  const value = props.value;
  const reviewPercentage =
    value.overall_review > 0
      ? `${value.overall_review_rating / value.overall_review}`
      : "0";

  const findPrice = () => {
    var data = value.rates.filter((e: any) => e.price > 0);

    return data[0].price;
  };

  return (
    <div className={props.className}>
      <div className="bg-white p-6 rounded-2xl">
        <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
          <div className="relative w-[130px] h-[130px] min-w-[130px] max-w-[130px]  mx-auto mb-9 lg:mb-0">
            <Link
              to={`${config.HOME_URL}/booking/profile/${value.member_code}`}
              target="_top"
            >
              <img
                className="w-[130px] h-[130px] min-w-[130px] max-w-[130px]  mb-3 rounded-full shadow-lg object-cover"
                src={`${config.AWS_URL_IMAGES}${value.profile_url}`}
                alt=""
              />
            </Link>
            <div className="absolute -translate-y-7 translate-x-4 rounded-full text-gray-700 bg-layout-green px-5 py-1">
              {value.year_of_experience
                .toString()
                .replaceAll("Years", "Tahun")
                .replaceAll("Year", "Tahun")}
            </div>
          </div>

          <div className="md:ml-16 leading-normal text-center md:text-left md:col-span-4">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
              {value.username}
            </h5>
            <p className="mb-2 font-normal text-sm text-gray-700">
              {value.specialist_title}
            </p>
            <div className="flex justify-center items-center md:justify-start">
              <img className="h-5 w-5" src="/images/svg/ic_star.svg" alt="" />
              <p className="ml-2 font-normal text-sm text-neutral-secondary">
                {parseFloat(reviewPercentage).toFixed(1)} (
                {value.overall_review} ulasan)
              </p>
            </div>
            <p className="mb-3 mt-2 text-sm font-normal line-clamp-4">
              {value.bio}
            </p>
            <div>
              {value.target_users !== null ? (
                <div>
                  <p className="font-bold text-custom-black text-xs mt-5 mb-3">
                    Tipe Klien
                  </p>
                  <div className="flex justify-center md:justify-start gap-2 flex-wrap">
                    {value.target_users.slice(0, 3).map((val: any) => {
                      return <Chip key={val.target_code}>{val.name}</Chip>;
                    })}
                    {value.target_users.length > 3 ? (
                      <Chip>+{value.target_users.length - 3} More</Chip>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="mx-8 hidden md:block md:col-span-1">
            <DividerVertical></DividerVertical>
          </div>

          <div className="border-b md:hidden w-full my-2"></div>

          <div className="flex-shrink-0 self-center flex flex-col gap-4 w-full md:w-auto md:col-span-2">
            <div className="font-medium text-center text-2xl">
              {utils.currencyFormatter(findPrice())}/
              <span className="text-lg text-neutral-secondary font-medium">
                sesi
              </span>
            </div>
            <Link
              to={`${config.HOME_URL}/booking/profile/${value.member_code}`}
              target="_top"
            >
              <ButtonBorder>Lihat Profil</ButtonBorder>
            </Link>
            <Link
              to={`${config.HOME_URL}/booking/${value.member_code}`}
              target="_top"
            >
              <ButtonGold className="px-6 py-3 text-center">
                Pesan Jadwal
              </ButtonGold>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
