/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonBlue } from "components/Button";
import Chip from "components/Chip";
import { config } from "helper/config";
import { DocumentText, User, Video } from "iconsax-react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getSpecialist,
  getSpecialistRates,
  getSpecialistReview,
} from "service/specialist";
import utils from "utils";

import { useEffect, useState } from "react";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { DividerVerticalMin } from "components/Divider";
import Rating from "@mui/material/Rating";
import { getAvailabilityFormatCall } from "service/schedule";
import moment from "moment";
import { CardRate } from "components/CardRate";
import SkeletonProfile from "components/skeleton/SkeletonProfile";

export default function Search() {
  const navigate = useNavigate();
  const params = useParams();
  const memberCode = params.member_code as string;

  const [reviewPage] = useState(1);
  const [reviewLimit, setReviewLimit] = useState(3);

  const onFetchProfile = (memberCode: string) => {
    return () =>
      getSpecialist({ memberCode: memberCode, lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const onFetchProfileReview = (memberCode: string) => {
    return () =>
      getSpecialistReview({
        memberCode: memberCode,
        page: reviewPage,
        limit: reviewLimit,
      }).then((val) => {
        return val.data;
      });
  };

  const onFetchRates = (memberCode: string) => {
    return () =>
      getSpecialistRates({
        memberCode: memberCode,
        lang: "id",
      }).then((val) => {
        return val.data;
      });
  };

  const profile = useQuery(
    "getSpecialistProfile",
    onFetchProfile(params.member_code as string)
  );

  const profileReview = useQuery(
    "getSpecialistProfileReview",
    onFetchProfileReview(memberCode)
  );

  const profileRates = useQuery("getSpecialistRates", onFetchRates(memberCode));

  const onFetchAvailabilityFormatCall = () => {
    return () =>
      getAvailabilityFormatCall({
        memberCode: memberCode,
        year: moment().year(),
        month: moment().month() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const queryFormatCall = useQuery(
    "getAvailabilityFormatCall",
    onFetchAvailabilityFormatCall()
  );
  const formatCalls = queryFormatCall.data;

  useEffect(() => {
    profileReview.refetch();
  }, [reviewLimit]);

  const onReviewShowAll = (limit: number) => {
    setReviewLimit(limit);
  };

  if (!profile.data) {
    return <SkeletonProfile></SkeletonProfile>;
  }

  const reviewPercentage =
    profile.data.overall_review > 0
      ? `${profile.data.overall_review_rating / profile.data.overall_review}`
      : "0";

  const openAppointmentType = (appointment: string) => {
    navigate(`/booking/${memberCode}?appointment_type=${appointment}`);
  };

  const getPrice = (appointmentType: string) => {
    const price = utils.currencyFormatter(
      profileRates.data.rates.find(
        (_data: { format_call: string }) =>
          _data.format_call === appointmentType
      )?.price
    );
    // console.log(appointmentType + " : " + price);
    return price.replace(/\u00A0/, " ");
  };

  return (
    <div>
      <div className="flex flex-col -mx-4 md:gap-8 md:mx-auto">
        <CardHeader
          profileUrl={`${config.AWS_URL_IMAGES}${profile.data.profile_url}`}
          name={profile.data.username}
          title={profile.data.specialist_title}
          yoe={profile.data.year_of_experience}
          reviewPercentage={reviewPercentage}
        ></CardHeader>

        {profile.data.introduction_video_url ? (
          <div className="">
            <div className="bg-white rounded-xl grid grid-cols-5">
              <div className="shrink-0 lg:rounded-l-md overflow-hidden col-span-2">
                <LiteYouTubeEmbed
                  id={utils.extractYoutubeID(
                    profile.data.introduction_video_url
                  )}
                  title=""
                />
              </div>
              <div className="col-span-3 p-8">
                <div className="mb-4 text-xl font-medium">Tentang Saya</div>
                <div>
                  {profile.data.bio
                    .split("\n")
                    .map(function (item: any, idx: any) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Card title="Tentang Saya">
            {profile.data.bio.split("\n").map(function (item: any, idx: any) {
              return (
                <span key={idx}>
                  {item}
                  <br />
                </span>
              );
            })}
          </Card>
        )}

        {profile.data.expertises ? (
          <Card title="Expertise Saya">
            <div className="flex gap-4 flex-wrap">
              {profile.data.expertises.map((val: any) => {
                return <Chip key={val.expertise_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.target_users ? (
          <Card title="Tipe Klien">
            <div className="flex gap-5 flex-wrap">
              {profile.data.target_users.map((val: any) => {
                return <Chip key={val.target_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.approaches ? (
          <Card title="Approach Saya">
            <div className="flex gap-5 flex-wrap">
              {profile.data.approaches.map((val: any) => {
                return <Chip key={val.approach_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.universities && profile.data.universities.length > 0 ? (
          <Card title="Edukasi">
            <div className="flex flex-col gap-4">
              {(profile.data.universities ?? []).map((val: any, i: number) => {
                return (
                  <div key={i}>
                    <div className="font-medium">{val.university}</div>
                    <div className="flex gap-3 items-center text-neutral-secondary">
                      <div>{val.graduate_year}</div>
                      <div className="bg-neutral-300 h-2 w-2 rounded-full"></div>
                      <div>{val.degree}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.licenses && profile.data.licenses.length > 0 ? (
          <Card title="Lisensi">
            <div className="flex flex-col gap-4">
              {(profile.data.licenses ?? []).map((val: any, i: number) => {
                return (
                  <div className="flex" key={i}>
                    <DocumentText variant="Bold"></DocumentText>
                    <div className="ml-5">{`${val.license_name} - ${val.license_number}`}</div>
                  </div>
                );
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.language ? (
          <Card title="Bahasa">
            <div className="flex gap-5 flex-wrap">
              {profile.data.language.map((val: string) => {
                return <Chip key={val}>{val}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profileReview.data ? (
          <CardReview
            overallReview={profile.data.overall_review}
            reviews={profileReview.data ?? []}
            onReviewShowAll={onReviewShowAll}
          ></CardReview>
        ) : null}

        <Card title="Harga">
          {formatCalls !== undefined ? (
            <div className="grid grid-cols-1 md:grid-cols-3  gap-6">
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Individual)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_individual"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_individual ||
                  getPrice("in_person_individual") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_individual")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Couple)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_couple"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_couple ||
                  getPrice("in_person_couple") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_couple")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Family)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_family"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_family ||
                  getPrice("in_person_family") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_family")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Child)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_child"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_child ||
                  getPrice("in_person_child") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_child")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Hypnotherapy)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_hypnotherapy"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_hypnotherapy ||
                  getPrice("in_person_hypnotherapy") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_hypnotherapy")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (Speech Therapy)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "in_person_speech_therapy"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_speech_therapy ||
                  getPrice("in_person_speech_therapy") === "Rp 0"
                }
                onClick={() => openAppointmentType("in_person_speech_therapy")}
              />
              <CardRate
                icon={<User size={40} variant="Bold" />}
                title={
                  <p>
                    Face to face
                    <br />
                    (SI Therapy)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call ===
                      "in_person_sensory_integration_therapy"
                  )?.price
                )}
                isHidden={
                  !formatCalls.in_person_sensory_integration_therapy ||
                  getPrice("in_person_sensory_integration_therapy") === "Rp 0"
                }
                onClick={() =>
                  openAppointmentType("in_person_sensory_integration_therapy")
                }
              />
              <CardRate
                icon={<Video size={40} variant="Bold" />}
                title={
                  <p>
                    Online Call
                    <br />
                    (Individual)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "online_call_individual"
                  )?.price
                )}
                isHidden={
                  !formatCalls.online_call_individual ||
                  getPrice("online_call_individual") === "Rp 0"
                }
                onClick={() => openAppointmentType("online_call_individual")}
              />
              <CardRate
                icon={<Video size={40} variant="Bold" />}
                title={
                  <p>
                    Online Call
                    <br />
                    (Couple)
                  </p>
                }
                price={utils.currencyFormatter(
                  profileRates.data.rates.find(
                    (_data: { format_call: string }) =>
                      _data.format_call === "online_call_couple"
                  )?.price
                )}
                isHidden={
                  !formatCalls.online_call_couple ||
                  getPrice("online_call_couple") === "Rp 0"
                }
                onClick={() => openAppointmentType("online_call_couple")}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Card>
      </div>

      <div className="flex justify-center mt-20">
        <Link to={`/booking/${memberCode}`}>
          <ButtonBlue className="px-6 py-3">Pesan jadwal sekarang</ButtonBlue>
        </Link>
      </div>
    </div>
  );
}

function Card(props: {
  title: string;
  children: any;
  additionalClass?: string;
}) {
  return (
    <div className="bg-white p-8 rounded-xl">
      <div className="mb-6 text-xl font-medium">{props.title}</div>
      <div>{props.children}</div>
    </div>
  );
}

function CardReview(props: {
  overallReview: number;
  reviews: any[];
  onReviewShowAll: (_: number) => void;
}) {
  return (
    <div className="bg-white p-8 rounded-xl">
      <div className="flex justify-between mb-6">
        <div className="flex gap-2 items-center">
          <div className="text-xl font-medium">Ulasan</div>
          <div className="text-neutral-secondary">
            ({props.overallReview} ulasan)
          </div>
        </div>
        {props.overallReview > 3 ? (
          <div
            className="text-blue-500 cursor-pointer"
            onClick={() =>
              props.onReviewShowAll(
                props.reviews.length > 3 ? 3 : props.overallReview
              )
            }
          >
            {props.reviews.length > 3 ? "Lihat Lebih Sedikit" : "Lihat Semua"}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col flex-wrap lg:flex-row pr-6">
        {props.reviews.map((val: any, index) => {
          return (
            <div
              className="p-2 w-full lg:basis-1/3 flex-shrink-0 overflow-hidden"
              key={index}
            >
              <div className="flex flex-col justify-between bg-blue-background h-full p-6 rounded-lg">
                <div className="opacity-60 overflow-hidden">
                  {val.review === "" ? "-" : val.review}
                </div>
                <div className="flex items-center gap-4 border-t pt-4 mt-6 text-neutral-secondary">
                  <div className="w-12 h-12 rounded-full bg-slate-200 shrink-0 text-black-app flex justify-center items-center text-2xl font-semibold">
                    {val.member_name[0].toUpperCase()}
                  </div>
                  <Rating
                    name="half-rating"
                    value={val.review_rating}
                    readOnly
                    precision={0.1}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CardHeader(props: {
  profileUrl: string;
  name: string;
  title: string;
  yoe: string;
  reviewPercentage: string;
}) {
  return (
    <div className="rounded-xl bg-white overflow-hidden">
      <img
        className="h-36 w-full object-cover"
        src="/images/svg/bg-header.svg"
        alt=""
      />
      <div className="flex flex-col items-center md:flex-row">
        <div className="md:ml-12 md:items-start">
          <div className="absolute -translate-y-20 -translate-x-1/2 z-10 md:-translate-y-44 md:translate-x-0">
            <div className="rounded-full flex items-center overflow-hidden w-48 h-48 bg-white">
              <img
                className="rounded-full w-45 h-45 shrink-0 mx-auto object-top"
                src={props.profileUrl}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="p-8 flex-grow mt-24 text-center md:text-left md:mt-0 md:ml-48">
          <div className="mb-3 text-3xl font-medium">{props.name}</div>
          <div className="mb-4 text-neutral-secondary">{props.title}</div>

          <div className="flex justify-center md:justify-start">
            <p className="text-lg">
              {props.yoe
                .toString()
                .replaceAll("Years", "Tahun")
                .replaceAll("Year", "Tahun")}{" "}
              Pengalaman
            </p>
            <DividerVerticalMin height="h-8 ml-4" />
            <img
              className="h-6 w-6 ml-4"
              src="/images/svg/ic_star.svg"
              alt=""
            />
            <p className="ml-1 text-lg text-custom-grey">
              {parseFloat(props.reviewPercentage).toFixed(1)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
