import api from "./api";

export const getCountry = async () => {
  const res = await api.get("/v1/administrative/countries");
  return res.data;
};

export const getGender = async (data: { lang: string }) => {
  var res: any[] = [];
  if (data.lang === "id") {
    res = [
      {
        key: "male",
        value: "Laki laki",
      },
      {
        key: "female",
        value: "Perempuan",
      },
    ];
  } else {
    res = [
      {
        key: "male",
        value: "Male",
      },
      {
        key: "female",
        value: "Female",
      },
    ];
  }

  return res;
};

export const getBirthyear = async () => {
  const res: String[] = [];
  const currentDate = new Date();

  for (
    let i = currentDate.getFullYear() - 60;
    i <= currentDate.getFullYear() - 16;
    i++
  ) {
    res.push(i.toString());
  }

  return res.reverse();
};

export const getMarital = async (data: { lang: string }) => {
  var res = [];
  if (data.lang === "id") {
    res = [
      {
        key: "single",
        value: "Lajang",
      },
      {
        key: "married",
        value: "Menikah",
      },
      {
        key: "divorced",
        value: "Cerai",
      },
      {
        key: "widowed",
        value: "Janda / Duda",
      },
      {
        key: "in_a_relationship",
        value: "Dalam hubungan",
      },
      {
        key: "prefer_not_to_say",
        value: "Lainnya",
      },
    ];
  } else {
    res = [
      {
        key: "single",
        value: "Single",
      },
      {
        key: "married",
        value: "Married",
      },
      {
        key: "divorced",
        value: "Divorced",
      },
      {
        key: "widowed",
        value: "Widowed",
      },
      {
        key: "in_a_relationship",
        value: "In a Relationship",
      },
      {
        key: "prefer_not_to_say",
        value: "Other",
      },
    ];
  }

  return res;
};

export const getReligion = async (data: { lang: string }) => {
  const res = await api.get("/v2/religion", {
    params: {
      lang: data.lang,
    },
  });

  return res.data;
};

export const getEducationLevel = async (data: { lang: string }) => {
  const res = await api.get("/v2/education-level", {
    params: {
      lang: data.lang,
    },
  });

  return res.data;
};
