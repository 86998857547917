export function CardAppointment(props: {
  icon: any;
  title: string;
  price: string;
  choosed: boolean;
  isHidden: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`flex gap-2 items-center justify-center cursor-pointer border rounded-lg py-3 ${
        props.choosed
          ? "bg-blue-background border-black-app"
          : "border-neutral-200"
      } ${props.isHidden ? " hidden" : ""}`}
      onClick={props.onClick}
    >
      {props.icon}
      <div className="w-3/5">
        <p className="text-xs md:text-sm mb-1 font-medium">{props.title}</p>
        <p className="text-xs md:text-base text-custom-black">{props.price}</p>
      </div>
    </div>
  );
}
