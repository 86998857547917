export function CardRate(props: {
  icon: any;
  title: any;
  price: string;
  isHidden: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`bg-blue-background rounded-lg border-2 border-blue-background hover:border-blue-app cursor-pointer p-8 ${
        props.isHidden ? " hidden" : ""
      }`}
      onClick={props.onClick}
    >
      <div className="flex gap-8 items-center">
        {props.icon}
        <div className="w-3/5">
          <p className="text-xs md:text-base mb-1 font-medium">{props.title}</p>
          <p className="text-xs md:text-base text-custom-black">
            {props.price}
          </p>
        </div>
      </div>
    </div>
  );
}
