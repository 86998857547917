export const ewallets = [
	{
		label: "GoPay",
		imgSrc: "/images/payment/gopay.png",
		payment_channel: "ewallet",
		payment_option: "gopay",
	},
	{
		label: "OVO Cash",
		imgSrc: "/images/payment/ovo.png",
		payment_channel: "ewallet",
		payment_option: "ovo",
	},
	{
		label: "ShopeePay",
		imgSrc: "/images/payment/shoppepay.png",
		payment_channel: "ewallet",
		payment_option: "shopeepay",
	},
	{
		label: "Dana",
		imgSrc: "/images/payment/dana.png",
		payment_channel: "ewallet",
		payment_option: "dana",
	},
];

export const transfers = [
	{
		label: "BCA",
		imgSrc: "/images/payment/bca.png",
		payment_channel: "va",
		payment_option: "bca",
	},
	{
		label: "BRI",
		imgSrc: "/images/payment/bri.png",
		payment_channel: "va",
		payment_option: "bri",
	},
	{
		label: "BNI",
		imgSrc: "/images/payment/bni.png",
		payment_channel: "va",
		payment_option: "bni",
	},
	{
		label: "Mandiri",
		imgSrc: "/images/payment/mandiri.png",
		payment_channel: "va",
		payment_option: "mandiri",
	},
];
