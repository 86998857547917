import api from "./api";

export const getSpecialist = async (params: {
  memberCode: string;
  lang?: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}`, {
    params: {
      lang: params.lang,
    },
  });

  return resp.data;
};

export const getSpecialistReview = async (params: {
  memberCode: string;
  lang?: string;
  page: number;
  limit: number;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/review`, {
    params: {
      lang: params.lang,
      page: params.page,
      limit: params.limit,
    },
  });

  return resp.data;
};

export const getSpecialistRates = async (params: {
  memberCode: string;
  lang?: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/rates`, {
    params: {
      lang: params.lang,
    },
  });

  return resp.data;
};

export const getSpecialists = (params: {
  page: number;
  limit: number;
  status?: string;
  keyword?: string;
  titles?: string;
  targets?: string;
  sortBy?: string;
  yoeLevel?: string;
  partnerType?: string;
}) => {
  return api
    .get("/v2/specialist", {
      params: {
        lang: "id",
        page: params.page,
        limit: params.limit,
        status: params.status ?? "active",
        keyword: params.keyword,
        targets: params.targets,
        specialist_title: params.titles,
        sort_by: params.sortBy,
        yoe_level: params.yoeLevel,
        partner_type: params.partnerType,
      },
    })
    .then((val) => val.data);
};
