import api from "./api";

export const checkPromo = async (params: {
  promoCode: string;
  amount: number;
}) => {
  const resp = await api.post("/v1/payment/check-promo", {
    promo_code: params.promoCode,
    amount: params.amount,
  });
  return resp.data;
};
