import { HTMLInputTypeAttribute } from "react";

export function InputSearch(props: {
  value?: string;
  defaultValue?: string;
  onChange: (_: string) => void;
  onSubmit: () => void;
}) {
  return (
    <div className="relative w-full">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          aria-hidden="true"
          className="w-5 h-5 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.onSubmit();
        }}
      >
        <input
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={(val) => props.onChange(val.target.value)}
          type="search"
          id="default-search"
          className="block w-full p-4 pl-10 text-sm text-gray-900 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
          placeholder="Cari Psikolog"
        />
      </form>
    </div>
  );
}

export function InputText(props: {
  id?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  defaultValue?: string;
  placeholder: string;
  onChange: (_: string) => void;
  disabled?: boolean;
}) {
  return (
    <input
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={(val) => props.onChange(val.target.value)}
      type={props.type}
      className="block w-full p-4 text-gray-900 rounded-lg bg-white border focus:ring-blue-500 focus:border-blue-500"
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
}

export function InputSelect(props: {
  title?: string;
  children: any;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (_: string) => void;
}) {
  const id = Math.random().toString();

  const onChange = (val: string) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  return (
    <div>
      {props.title ? (
        <label
          htmlFor={id}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {props.title}
        </label>
      ) : null}
      <select
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={(val) => onChange(val.target.value)}
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4"
        disabled={props.disabled}
        multiple={props.multiple}
      >
        {props.children}
      </select>
    </div>
  );
}

export function InputArea(props: {
  title: string;
  placeholder: string;
  rows?: number;
  value?: string;
  defaultValue?: string;
  onChange?: (_: string) => void;
  disabled?: boolean;
}) {
  const id = Math.random().toString();

  const onChange = (val: string) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {props.title}
      </label>
      <textarea
        value={props.value}
        onChange={(val) => onChange(val.target.value)}
        id={id}
        rows={props.rows ?? 4}
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        placeholder={props.placeholder}
        disabled={props.disabled}
      ></textarea>
    </div>
  );
}
