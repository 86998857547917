import { useGoogleLogin } from "@react-oauth/google";
import { LoginForm } from "components/auth/Login";
import { config } from "helper/config";
import {
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authLogin, authLoginSosmed, checkUserGoogle } from "service/auth";
import utils, { CapitalizeFirstLetter } from "utils";
import validator from "validator";

export function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const isValidAuth = () => {
    const validPassword = password.length > 6;
    const validEmail = validator.isEmail(email);

    return validEmail && validPassword;
  };

  const onSubmit = async () => {
    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }
    setIsLoading(true);

    try {
      // Login First
      const res = await authLogin({
        email: email,
        password: password,
      });
      const data = res.data;
      const token = data.token;

      if (data.role_name !== "member") {
        toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
        window.scrollTo({ behavior: "smooth", top: 0 });
        utils.postMessageScrollTop();
      } else {
        await setTokenAuth(token);
        setNameCookies(data.username);
        setRoleCookies(data.role_name);
        setUserData({
          username: data.username,
          email: data.email,
          profile_url: data.profile_url,
          role_name: data.role_name,
        });

        setIsLoading(false);

        const redirectUrl = searchParams.get("redirect_url");
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate(`/booking`);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error as string);
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      loginGoogle("gmail", tokenResponse.access_token, userInfo.email);
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const loginGoogle = async (type: string, token: string, emailGoogle: any) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;
        if (data.role_name !== "member") {
          toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        } else {
          setTokenAuth(token);
          setNameCookies(data.username);
          setRoleCookies(data.role_name);
          setUserData({
            username: data.username,
            email: data.email,
            profile_url: data.profile_url,
            role_name: data.role_name,
          });

          const redirectUrl = searchParams.get("redirect_url");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate(`/booking`);
          }
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="max-w-2xl mx-auto px-4 my-16">
      <div className="font-medium text-xl mb-12 text-center">
        Masuk ke akunmu
      </div>
      <LoginForm
        email={email}
        password={password}
        isLoading={isLoading}
        isLoadingGoogle={isLoadingGoogle}
        setAuthType={(val) => navigate(`/booking/register`)}
        setEmail={setEmail}
        setPassword={setPassword}
        onSubmit={onSubmit}
        onSubmitGoogle={onSubmitGoogle}
      ></LoginForm>
    </div>
  );
}
