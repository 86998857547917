export function DividerVertical() {
  return (
    <div className="inline-block h-[190px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100"></div>
  );
}

export function DividerVerticalMin(props: { height: string }) {
  return (
    <div
      className={
        "inline-block min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 " +
        props.height
      }
    ></div>
  );
}
