import Skeleton from "@mui/material/Skeleton";
import React from "react";

const SkeletonFilterMenu = () => {
  return (
    <div className="mb-4">
      <Skeleton
        width={280}
        height={30}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "0.5rem",
        }}
      />
      <Skeleton
        width={200}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "1rem",
        }}
      />
      <Skeleton
        width={200}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "1rem",
        }}
      />
      <Skeleton
        width={200}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "1rem",
        }}
      />
    </div>
  );
};

export default SkeletonFilterMenu;
