import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";

import { ScrollToTop } from "components/ScrollTop";
import { queryClient } from "helper/query";
import { useEffect, useRef } from "react";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import utils from "utils";
import RoutePage from "./route";

function App() {
  const contentRef = useRef<HTMLDivElement>(null);

  const onScrollToTop = () => {
    if (!window.top) return;

    utils.postMessageScrollTop();
  };

  const onResize = () => {
    if (!window.top) return;

    const message = JSON.stringify({
      type: "height",
      value: contentRef.current?.scrollHeight,
    });
    window.top.postMessage(message, "*");
  };

  // Start observing the element when the component is mounted
  useEffect(() => {
    const element = contentRef.current;
    if (!element) return;

    const observer = new ResizeObserver(onResize);
    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      className={`px-4 w-full ${
        process.env!.NODE_ENV === "development" ? "bg-slate-100" : ""
      }`}
      ref={contentRef}
    >
      <div className="max-w-7xl mx-auto pt-6 pb-24 h-full">
        <QueryClientProvider client={queryClient}>
          <RoutePage></RoutePage>
        </QueryClientProvider>
        <ToastContainer />
        <ScrollToTop onScrollToTop={onScrollToTop} />
      </div>
    </div>
  );
}

export default App;
