const onlyAlphanum = (text?: string) => {
  if (!text) {
    return "";
  }

  return text.replace(/[^0-9a-zA-Z ]/g, "");
};

export const CapitalizeFirstLetter = (str: string) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

function currencyFormatter(
  number: number,
  locale = "id-ID",
  currency = "IDR"
): string {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    currency: currency,
    style: "currency",
  }).format(number ?? 0);
}

function dateFormatter(val: Date, locale = "id-ID") {
  return new Intl.DateTimeFormat(locale).format(val);
}

function timeZeroLeading(value: number) {
  if (value < 10) {
    return `0${value}`;
  }

  return value;
}

const getBirthYears = () => {
  const curYear = new Date().getFullYear();
  const years: number[] = [];

  for (let year = curYear; year >= 1950; year--) {
    years.push(year);
  }

  return years;
};

function extractYoutubeID(url: string) {
  const urls = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return urls[2] !== undefined ? urls[2].split(/[^0-9a-z_\-]/i)[0] : urls[0];
}

function postMessageScrollTop() {
  if (!window.top) return;

  const message = JSON.stringify({
    type: "scroll_top",
  });
  window.top.postMessage(message, "*");
}

const utils = {
  onlyAlphanum,
  currencyFormatter,
  dateFormatter,
  timeZeroLeading,
  getBirthYears,
  extractYoutubeID,
  postMessageScrollTop,
};

export default utils;
