import api from "./api";

export const getSchedules = async (params: {
  memberCode: string;
  status?: string;
  formatCall: string;
  locationCode: string;
  date: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/schedule`, {
    params: {
      status: params.status,
      format_call: params.formatCall,
      location_code: params.locationCode,
      date: params.date,
    },
  });

  return resp.data;
};
export const getSchedulesV2 = async (params: {
  memberCode: string;
  status?: string;
  formatCall: string;
  locationCode: string;
  date: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/schedule_v2`, {
    params: {
      status: params.status,
      format_call: params.formatCall,
      location_code: params.locationCode,
      date: params.date,
    },
  });

  return resp.data;
};

export const getSchedule = async (params: {
  memberCode: string;
  scheduleDetailCode: string;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/${params.scheduleDetailCode}`
  );

  return resp.data;
};

// @deprecated
export const getScheduleMonthly = async (params: {
  memberCode: string;
  formatCall: string;
  locationCode: string;
  year: number;
  month: number;
}) => {
  return api
    .get(`/v2/specialist/${params.memberCode}/schedule/monthly`, {
      params: {
        format_call: params.formatCall,
        location_code: params.locationCode,
        year: params.year,
        month: params.month,
      },
    })
    .then((val) => val.data);
};

export const getScheduleMonthlyV2 = async (params: {
  memberCode: string;
  formatCall: string;
  locationCode: string;
  year: number;
  month: number;
}) => {
  return api
    .get(`/v2/specialist/${params.memberCode}/schedule/monthly_v2`, {
      params: {
        format_call: params.formatCall,
        location_code: params.locationCode,
        year: params.year,
        month: params.month,
      },
    })
    .then((val) => val.data);
};

export const getScheduleByReservation = async (params: {
  reservationCode: string;
}) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${params.reservationCode}/schedule`
    )
    .then((val) => val.data);
};

export const getAvailabilityFormatCall = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};

export const getAvailabilityLocation = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call/location`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};
export const getAvailabilityLocationV2 = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call/location_v2`,
    {
      params: {
        year: params.year,
        month: params.month,
      },
    }
  );

  return resp.data;
};

export const getLocationDetail = async (params: { locationCode: string }) => {
  const resp = await api.get(`/v2/location/${params.locationCode}`);

  return resp.data;
};
