import api from "./api";

export const cancelPayment = (params: { paymentCode: string }) => {
  return api
    .post(`/v1/payment/${params.paymentCode}/cancel`)
    .then((val) => val.data);
};

export const cancelPaymentV2 = (params: { paymentCode: string }) => {
  return api
    .post(`/v1/payment/${params.paymentCode}/cancel_v2`)
    .then((val) => val.data);
};

export const simPaymentCallback =
  process.env.REACT_APP_STAGE !== "production"
    ? (params: {
        status: string;
        payment_id: string;
        order_id: string;
        amount: number;
        payment_method: string;
      }) => {
        const now = new Date();
        const payload = {
          event: `payment.${params.status}`,
          data: {
            id: params.payment_id,
            order_id: params.order_id,
            paid_at: now,
            created_at: now,
            payment_method: params.payment_method,
            amount: params.amount,
          },
        };

        return api
          .post(`/v1/payment/callback`, payload)
          .then((val) => val.data);
      }
    : () => {};
