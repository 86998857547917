/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonBlue, ButtonBorder, ButtonGold } from "components/Button";
import { InputText } from "components/Input";
import { Title } from "components/Text";
import { config } from "helper/config";
import {
  clearTokenAuth,
  getTokenAuth,
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  authLogin,
  authLoginSosmed,
  authRegistration,
  authRegistrationSosmed,
  checkUserGoogle,
  requestOTP,
  verifyEmail,
} from "service/auth";
import { getProfile, updateProfile } from "service/profile";
import { checkPromo } from "service/promo";
import { bookReservationV2 } from "service/reservation";
import { getLocationDetail, getSchedule } from "service/schedule";
import { getSpecialist, getSpecialistRates } from "service/specialist";
import utils, { CapitalizeFirstLetter } from "utils";
import validator from "validator";
import { ewallets, transfers } from "./content";
import Stepper from "components/stepper";
import { RegisterForm } from "components/auth/Register";
import { LoginForm } from "components/auth/Login";
import { useGoogleLogin } from "@react-oauth/google";
import OtpInput from "components/cotp";
import {
  getBirthyear,
  getCountry,
  getEducationLevel,
  getGender,
  getMarital,
  getReligion,
} from "service/global";
import SkeletonPayment from "components/skeleton/SkeletonPayment";

export default function Payment() {
  const navigate = useNavigate();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const specialistCode = params.specialist_code as string;
  const appointmentType =
    searchParams.get("appointment_type") ?? "in_person_individual";
  const locationCode = searchParams.get("location") ?? "";
  const scheduleDetailCode = params.schedule_detail_code as string;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [birthyear, setBirthyear] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [marital, setMarital] = useState("");

  const [authType, setAuthType] = useState(config.constant.signup);

  const [promo, setPromo] = useState(0);
  const [promoCode, setPromoCode] = useState(
    searchParams.get("promoCode") ?? ""
  );

  useEffect(() => {
    if (searchParams.get("promoCode")) onCheckPromo();
  }, [promoCode]);

  const [paymentChannel, setPaymentChannel] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [phoneOvo, setPhoneOvo] = useState("");

  const IN_PERSON_CHILD = "in_person_child";
  const IN_PERSON_COUPLE = "in_person_couple";
  const IN_PERSON_FAMILY = "in_person_family";
  const IN_PERSON_INDIVIDUAL = "in_person_individual";
  const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
  const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
  const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
  const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
  const ONLINE_CALL_COUPLE = "online_call_couple";

  const onGetSchedule = () => {
    return () =>
      getSchedule({
        memberCode: specialistCode,
        scheduleDetailCode: scheduleDetailCode,
      }).then((val) => val.data);
  };

  const onFetchProfile = () => {
    return () =>
      getSpecialist({ memberCode: specialistCode }).then((val) => val.data);
  };

  const onFetchLocation = () => {
    return () =>
      getLocationDetail({
        locationCode: locationCode,
      }).then((val) => {
        return val.data;
      });
  };

  const profileLocation = useQuery("getSpecialistLocation", onFetchLocation());

  const onFetchRates = (memberCode: string) => {
    return () =>
      getSpecialistRates({
        memberCode: memberCode,
        lang: "id",
      }).then((val) => {
        return val.data;
      });
  };

  const profileRates = useQuery(
    "getSpecialistRates",
    onFetchRates(specialistCode)
  );

  const onGetProfile = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    return () => {
      try {
        return getProfile()
          .then((res) => res.data)
          .catch((err) => {});
      } catch (error) {
        return clearTokenAuth();
      }
    };
  };

  const isValidAuth = () => {
    const validPassword = password.length > 6;
    const validName = name.length > 3;
    const validEmail = validator.isEmail(email);
    const validData =
      phone.length > 10 &&
      country !== "" &&
      birthyear !== "" &&
      gender !== "" &&
      educationLevel !== "" &&
      marital !== "" &&
      religion !== "";

    if (authType === config.constant.login) {
      return validEmail && validPassword;
    }

    if (authType === config.constant.signup) {
      return validName && validPassword && validEmail && validData;
    }

    return true;
  };

  // USE QUERY //
  const querySchedule = useQuery("getSchedule", onGetSchedule());
  const schedule = querySchedule.data;

  const querySpecialist = useQuery("getSpecialist", onFetchProfile());
  const specialist = querySpecialist.data ?? {};

  const queryProfile = useQuery(
    "getProfile",
    onGetProfile(),
    config.useQuery.fetchOnce as any
  );
  const profile = queryProfile.data ?? {};

  useEffect(() => {
    const data = querySchedule.data;
    if (!data) {
      return;
    }

    const status = data.status;
    if (status !== "active" && status !== "available") {
      navigate(`/booking/${specialistCode}`);
    }
  }, [querySchedule.data]);

  useEffect(() => {
    if (queryProfile.data != null) {
      setAuthType(config.constant.loged);
    }
  }, [queryProfile.data]);

  const isFullPoint = () => total === 0;
  const hourlyPrice =
    profileRates.data !== undefined
      ? profileRates.data.rates !== null
        ? profileRates.data.rates.find(
            (_data: { format_call: string }) =>
              _data.format_call === appointmentType
          ).price
        : 0
      : 0;
  const total = hourlyPrice - promo;
  const isEnabledPay =
    ((paymentChannel && paymentOption) || isFullPoint()) &&
    authType === config.constant.loged;

  const isEnabledClaim = promoCode !== "";

  const isEnabledOVOPay = phoneOvo !== "";

  const [showOVOSection, setShowOVOSection] = useState(false);

  const onSubmit = async () => {
    if (paymentOption === "ovo") {
      setShowOVOSection(true);

      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();
    } else {
      submitPayment();
    }
  };

  const changeOtherPayment = () => {
    setShowOVOSection(false);

    window.scrollTo({ behavior: "smooth", top: 0 });
    utils.postMessageScrollTop();
  };

  const isValidPhone = () => {
    const validPhone = validator.isMobilePhone(phoneOvo);

    return validPhone;
  };

  const submitPaymentOvo = async () => {
    if (!isValidPhone()) {
      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();

      return toast.warning("Nomor HP Anda Tidak Valid");
    }

    let paymentType = "payment_gateaway";

    const bookResp = await bookReservationV2({
      formatCall: appointmentType,
      location: locationCode,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      paymentPhone: phoneOvo,
      scheduleDetailCode: scheduleDetailCode,
      specialistCode: specialistCode,
      promoCode: promoCode,
      promoApplied: promo,
    });
    const data = bookResp.data;
    const reservationCode = data.reservation_code;

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    navigate(`/booking/reservation/${reservationCode}`);
  };

  const submitPayment = async () => {
    let paymentType = "payment_gateaway";
    bookReservationV2({
      formatCall: appointmentType,
      location: locationCode,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      scheduleDetailCode: scheduleDetailCode,
      specialistCode: specialistCode,
      promoCode: promoCode,
      promoApplied: promo,
    })
      .then((bookResp) => {
        const data = bookResp.data;
        const reservationCode = data.reservation_code;

        if (paymentChannel === "ewallet") {
          const redirect =
            data.payment_gateaway_data.raw_response.response.checkout_url;

          window.open(redirect, "_blank");
        }

        navigate(`/booking/reservation/${reservationCode}`);
      })
      .catch((error) => {
        return toast.warning(error);
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const onSignup = async () => {
    if (authType !== config.constant.signup) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    setIsLoadingRegister(true);

    try {
      // Signup First
      const res = await authRegistration({
        email: email,
        password: password,
      });
      const token = res.data.token;

      setTokenAuth(token);

      const resProfile = await updateProfile({
        profile_url: "",
        username: name,
        phone: phone,
        email: email,
        gender_key: gender,
        birthyear: birthyear,
        marital: marital,
        country: country,
        religion_id: religion,
        education_level_id: educationLevel,
        is_register: true,
      });

      const data = resProfile.data;

      setNameCookies(data.username);
      setRoleCookies(data.role_name);

      setUserData({
        member_code: data.member_code,
        username: data.username,
        email: data.email,
        profile_url: data.profile_url,
        role_name: data.role_name,
      });

      setIsLoadingRegister(false);
      requestOTP().then((res) => {
        setCanResend(false);
        setShowVerifyOTP(true);
        setTimeLeft(30);
      });
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onLogin = async () => {
    if (authType !== config.constant.login) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    setIsLoading(true);
    try {
      // Login First
      const res = await authLogin({
        email: email,
        password: password,
      });
      const data = res.data;
      const token = data.token;
      setIsLoading(false);

      if (data.role_name !== "member") {
        toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
        window.scrollTo({ behavior: "smooth", top: 0 });
        utils.postMessageScrollTop();
      } else {
        setTokenAuth(token);
        setNameCookies(data.username);
        setRoleCookies(data.role_name);
        setUserData({
          member_code: data.member_code,
          username: data.username,
          email: data.email,
          profile_url: data.profile_url,
          role_name: data.role_name,
        });

        queryProfile.refetch();
        navigate(
          `/booking/${specialistCode}/${scheduleDetailCode}?appointment_type=${appointmentType}&promo_code=${promoCode}&location=${locationCode}`
        );
      }
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      loginGoogle("gmail", tokenResponse.access_token, userInfo.email);
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const loginGoogle = async (type: string, token: string, emailGoogle: any) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;

        if (data.role_name !== "member") {
          toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        } else {
          setTokenAuth(token);
          setNameCookies(data.username);
          setRoleCookies(data.role_name);
          setUserData({
            member_code: data.member_code,
            username: data.username,
            email: data.email,
            profile_url: data.profile_url,
            role_name: data.role_name,
          });

          queryProfile.refetch();

          navigate(
            `/booking/${specialistCode}/${scheduleDetailCode}?appointment_type=${appointmentType}&promo_code=${promoCode}&location=${locationCode}`
          );
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onRegisterGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      registerGoogle(
        "gmail",
        tokenResponse.access_token,
        userInfo.email,
        userInfo.name
      );
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const registerGoogle = async (
    type: string,
    token: string,
    emailGoogle: string,
    nameGoogle: string
  ) => {
    setIsLoadingGoogle(true);
    authRegistrationSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
      code: "",
    })
      .then((res) => {
        const data = res.data;
        const token = data.token;

        setTokenAuth(token);

        updateProfile({
          profile_url: "",
          username: nameGoogle,
        }).then((val) => {
          const updatedData = val.data;

          setNameCookies(updatedData.username);
          setRoleCookies(data.role_name);

          setUserData({
            member_code: updatedData.member_code,
            username: updatedData.username,
            email: updatedData.email,
            profile_url: updatedData.profile_url,
            role_name: updatedData.role_name,
          });

          setIsLoadingGoogle(false);
          requestOTP().then((res) => {
            setCanResend(false);
            setShowVerifyOTP(true);
            setTimeLeft(30);
          });
        });
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        if (err.toString().toLowerCase().includes("email already exist")) {
          loginGoogle("gmail", token, emailGoogle);
        } else {
          toast.warn(CapitalizeFirstLetter(err));
        }
      });
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  const [countries, setCountries] = useState<any[]>([]);
  const [birthyears, setBirthyears] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [educationLevels, setEducationLevels] = useState<any[]>([]);
  const [religions, setReligions] = useState<any[]>([]);
  const [maritals, setMaritals] = useState<any[]>([]);

  useEffect(() => {
    getCountry().then((val) => {
      setCountries(val.data ?? []);
    });

    getBirthyear().then((val) => {
      setBirthyears(val ?? []);
    });

    getGender({ lang: "id" }).then((val) => {
      setGenders(val ?? []);
    });

    getEducationLevel({ lang: "id" }).then((val) => {
      setEducationLevels(val.data);
    });

    getReligion({ lang: "id" }).then((val) => {
      setReligions(val.data);
    });

    getMarital({ lang: "id" }).then((val) => {
      setMaritals(val ?? []);
    });
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const onResend = async () => {
    requestOTP().then((res: any) => {
      setTimeLeft(30);
      setCanResend(false);
    });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyEmail({
      pin: otp,
    })
      .then((res: any) => {
        setShowVerifyOTP(false);
        setIsLoadingVerify(false);
        queryProfile.refetch();
        navigate(
          `/booking/${specialistCode}/${scheduleDetailCode}?appointment_type=${appointmentType}&promo_code=${promoCode}&location=${locationCode}`
        );

        setAuthType(config.constant.loged);
      })
      .catch((err: string) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onChangePayment = (channel: string, option: string) => {
    if (total === 0) {
      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();

      return toast.success("payment already used point");
    }
    setPaymentChannel(channel);
    setPaymentOption(option);
  };

  const onCheckPromo = async () => {
    if (promoCode === "") {
      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();

      return toast.warn("please input promo code first");
    }

    checkPromo({
      amount: hourlyPrice,
      promoCode: promoCode,
    })
      .then((res) => {
        setPromo(res.data.promo_applied);
        toast.success("Berhasil Memakai Promo");
        window.scrollTo({ behavior: "smooth", top: 0 });
        utils.postMessageScrollTop();
      })
      .catch((err) => {
        toast.warn(CapitalizeFirstLetter(err));
        window.scrollTo({ behavior: "smooth", top: 0 });
        utils.postMessageScrollTop();
      });
  };

  if (
    querySpecialist.isLoading ||
    querySchedule.isLoading ||
    queryProfile.isLoading
  ) {
    return <SkeletonPayment></SkeletonPayment>;
  }

  return (
    <div>
      <Stepper step={2}></Stepper>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:basis-7/12">
          {showOVOSection ? (
            <div>
              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl flex flex-col gap-4">
                <div className="w-full flex flex-col items-center justify-center gap-4">
                  <img src="/images/svg/ovo.svg" className="w-20" alt="ovo" />
                  <p>Masukkan nomor yang terdaftar di OVO</p>
                </div>
                <label
                  className="text-lg font-medium text-black-app"
                  htmlFor="phone"
                >
                  No HP*
                </label>
                <InputText
                  placeholder="Nomor yang terdaftar di OVO"
                  onChange={(val) => setPhoneOvo(val)}
                  defaultValue={phoneOvo}
                  type="tel"
                  id="phone"
                ></InputText>
                <ButtonBlue
                  className={`py-3 px-8 mt-4 ${
                    isEnabledOVOPay ? "" : "bg-neutral-200"
                  }`}
                  onClick={submitPaymentOvo}
                >
                  <p className="text-sm font-medium">Bayar Sekarang</p>
                </ButtonBlue>
                <ButtonBorder onClick={changeOtherPayment}>
                  Ganti Pembayaran Lain
                </ButtonBorder>
              </div>
            </div>
          ) : showVerifyOTP ? (
            <div className="border-0 rounded-lg shadow-lg flex justify-center w-full bg-white focus:outline-none">
              <div className="text-center px-20 py-16">
                <p className="text-lg font-semibold">Verifikasi Email Anda</p>
                <p className="text-sm font-normal text-text-grey mt-4">
                  Silakan masukkan 4 Digit kode yang dikirimkan ke
                </p>
                <p className="text-sm font-medium text-text-grey mb-6">
                  {email}
                </p>
                <div className="flex justify-center">
                  <OtpInput
                    value={otp}
                    valueLength={4}
                    onChange={onChangeOTP}
                  />
                </div>
                <div className="mt-6">
                  {canResend ? (
                    <div className="flex justify-center">
                      <p className="text-sm text-text-grey">
                        Tidak menerima kode?
                      </p>
                      <p
                        className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                        onClick={() => {
                          onResend();
                        }}
                      >
                        Kirim Ulang Kode
                      </p>
                    </div>
                  ) : (
                    <p className="text-sm text-text-grey">
                      {"Harap tunggu " +
                        timeLeft +
                        " detik untuk mengirim ulang kode"}
                    </p>
                  )}
                </div>
                <button
                  type="button"
                  disabled={isBtnVerifyDisabled}
                  className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-10 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                  onClick={() => onVerify()}
                >
                  <strong>
                    {isLoadingVerify ? "Memverifikasi..." : "Verifikasi"}
                  </strong>
                  {isLoadingVerify ? (
                    <div
                      className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    ></div>
                  ) : null}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:gap-8">
              {authType === config.constant.login ? (
                <LoginForm
                  email={email}
                  password={password}
                  isLoading={isLoading}
                  isLoadingGoogle={isLoadingGoogle}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setAuthType={setAuthType}
                  onSubmit={onLogin}
                  onSubmitGoogle={onSubmitGoogle}
                ></LoginForm>
              ) : null}

              {authType === config.constant.loged ? (
                <LogedSection
                  onAuthTypeLogin={() => setAuthType(config.constant.login)}
                  profile={profile}
                ></LogedSection>
              ) : null}

              {authType === config.constant.signup ? (
                <RegisterForm
                  isValidInput={isValidAuth()}
                  email={email}
                  name={name}
                  password={password}
                  country={country}
                  birthyear={birthyear}
                  gender={gender}
                  religion={religion}
                  educationLevel={educationLevel}
                  marital={marital}
                  phone={phone}
                  countries={countries}
                  birthyears={birthyears}
                  genders={genders}
                  religions={religions}
                  educationLevels={educationLevels}
                  maritals={maritals}
                  isLoading={isLoadingRegister}
                  isLoadingGoogle={isLoadingGoogle}
                  setAuthType={setAuthType}
                  setName={setName}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setCountry={setCountry}
                  setGender={setGender}
                  setBirthyear={setBirthyear}
                  setMarital={setMarital}
                  setEducationLevel={setEducationLevel}
                  setReligion={setReligion}
                  setPhone={setPhone}
                  onSubmit={onSignup}
                  onSubmitGoogle={onRegisterGoogle}
                ></RegisterForm>
              ) : null}

              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              {authType === config.constant.loged ? (
                <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl flex flex-col gap-6">
                  <div className="font-medium text-xl text-black-app">
                    Payment Method
                  </div>
                  <div>
                    <div className="font-semibold text-sm text-black-app">
                      E-Wallet
                    </div>
                    <div className="flex flex-col gap-3 my-4">
                      {ewallets.map((val) => {
                        return (
                          <ItemPayment
                            key={val.label}
                            imgSrc={val.imgSrc}
                            label={val.label}
                            isChecked={
                              paymentChannel === val.payment_channel &&
                              paymentOption === val.payment_option
                            }
                            onClick={() =>
                              onChangePayment(
                                val.payment_channel,
                                val.payment_option
                              )
                            }
                          ></ItemPayment>
                        );
                      })}
                    </div>
                    <div className="font-semibold text-sm text-black-app">
                      Bank Transfer
                    </div>
                    <div className="flex flex-col gap-3 my-4">
                      {transfers.map((val) => {
                        return (
                          <ItemPayment
                            key={val.label}
                            imgSrc={val.imgSrc}
                            label={val.label}
                            isChecked={
                              paymentChannel === val.payment_channel &&
                              paymentOption === val.payment_option
                            }
                            onClick={() =>
                              onChangePayment(
                                val.payment_channel,
                                val.payment_option
                              )
                            }
                          ></ItemPayment>
                        );
                      })}
                    </div>

                    <div className="font-semibold text-sm text-black-app mb-4">
                      QRIS
                    </div>
                    <ItemPayment
                      imgSrc="/images/payment/qris.png"
                      label="QRIS"
                      isChecked={paymentChannel === "qris"}
                      onClick={() => onChangePayment("qris", "dana")}
                    ></ItemPayment>

                    <div className="font-semibold text-xl text-black-app mt-4">
                      Promo
                    </div>
                    <div className="my-3 flex">
                      <InputText
                        onChange={(val) => setPromoCode(val)}
                        placeholder="Insert promo code"
                        value={promoCode}
                        type="text"
                      ></InputText>
                      <ButtonGold
                        className={`py-3 px-8 flex items-center ml-4 ${
                          isEnabledClaim ? "" : "bg-neutral-200"
                        }`}
                        onClick={onCheckPromo}
                      >
                        Claim
                      </ButtonGold>
                    </div>
                  </div>
                  <ButtonBlue
                    className={`py-3 px-8 ${
                      isEnabledPay ? "" : "bg-neutral-200"
                    }`}
                    onClick={onSubmit}
                  >
                    <p className="text-lg font-medium">
                      {paymentOption === "ovo" ? "Masukkan No HP" : "Bayar"}
                    </p>
                  </ButtonBlue>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="hidden md:block lg:basis-5/12 lg:pl-10">
          <BookingDetailSection />
        </div>
      </div>
    </div>
  );

  function BookingDetailSection() {
    return (
      <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl">
        <Title>Detail Pemesanan</Title>
        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
          <div className="shrink-0">
            <img
              className="rounded-full w-16 h-16"
              src={`${config.AWS_URL_IMAGES}${specialist.profile_url}`}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xl font-medium">{specialist.username}</div>
              <div className="text-neutral-secondary">
                {specialist.specialist_title}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">
                Janji Temu
              </div>
              <div className="text-neutral-800">
                {appointmentType === IN_PERSON_INDIVIDUAL
                  ? "Face to face (Individual)"
                  : appointmentType === IN_PERSON_COUPLE
                  ? "Face to face (Couple)"
                  : appointmentType === IN_PERSON_FAMILY
                  ? "Face to face (Family)"
                  : appointmentType === IN_PERSON_CHILD
                  ? "Face to face (Child)"
                  : appointmentType === IN_PERSON_HYPNOTHERAPY
                  ? "Face to face (Hypnotherapy)"
                  : appointmentType === IN_PERSON_SI_THERAPY
                  ? "Face to face (SI Therapy)"
                  : appointmentType === IN_PERSON_SPEECH_THERAPY
                  ? "Face to face (Speech Therapy)"
                  : appointmentType === ONLINE_CALL_INDIVIDUAL
                  ? "Online Call (Individual)"
                  : "Online Call (Couple)"}{" "}
                60 menit
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">Lokasi</div>
              <div className="text-neutral-800">
                {appointmentType === ONLINE_CALL_INDIVIDUAL ||
                appointmentType === ONLINE_CALL_COUPLE
                  ? `Online`
                  : `${profileLocation.data.name} - ${profileLocation.data.address}`}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">Tanggal</div>
              <div className="text-neutral-800">
                {moment(schedule.date).format("MMMM D, YYYY")}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">Jam</div>
              <div className="text-neutral-800">
                {moment(schedule.time_from, "HH:mm").format("hh:mm A")}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Subtotal</div>
            <div className="text-lg text-neutral-primary">
              {utils.currencyFormatter(hourlyPrice)}
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Promo</div>
            <div className="text-lg text-neutral-primary">
              {promo > 0 ? `-${utils.currencyFormatter(promo)}` : "Rp0"}
            </div>
          </div>
        </div>

        <div className="flex justify-between text-xl font-medium text-black-app mt-6">
          <div>Total</div>
          <div>{total > 0 ? `${utils.currencyFormatter(total)}` : "Rp0"}</div>
        </div>
      </div>
    );
  }
}

function LogedSection(props: { profile: any; onAuthTypeLogin: () => void }) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">
        Hai, {props.profile.username}
      </div>
      <div>Kamu masuk sebagai {props.profile.email}</div>
      <div>
        Jika ini bukan akun Anda.{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={props.onAuthTypeLogin}
        >
          Ganti akun
        </span>
      </div>
    </div>
  );
}

function ItemPayment(props: {
  label: string;
  imgSrc: string;
  isChecked: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className="border rounded-md px-5 py-4 flex justify-between cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex">
        <img
          className="object-contain w-24 h-5 object-left"
          src={props.imgSrc}
          alt=""
        />
        <div>{props.label}</div>
      </div>
      <div>
        {props.isChecked ? (
          <img className="w-6 h-6" src="/images/svg/check.svg" alt="" />
        ) : (
          <div className="w-6 h-6 border rounded-full"></div>
        )}
      </div>
    </div>
  );
}
