import { Loading } from "components/Loading";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { onJoinGMeet } from "helper";
import { config } from "helper/config";
import { TickCircle } from "iconsax-react";
import moment from "moment";
import { useState } from "react";
import ApiCalendar from "react-google-calendar-api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateReservation } from "service/reservation";
import utils from "utils";

const IN_PERSON_CHILD = "in_person_child";
const IN_PERSON_COUPLE = "in_person_couple";
const IN_PERSON_FAMILY = "in_person_family";
const IN_PERSON_INDIVIDUAL = "in_person_individual";
const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
const ONLINE_CALL_COUPLE = "online_call_couple";

const configCalendar = {
  clientId: config.GOOGLE_CLIENT_ID,
  apiKey: config.GOOGLE_API_KEY,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(configCalendar);

export default function ConfirmedPayment(props: {
  reservation: any;
  counselingAnswer: any;
  onCreateCounselingAnswer: (_: any) => void;
}) {
  const reservation = props.reservation;
  const total = (reservation.amount ?? 0) - reservation.promo_used;

  const onCopyLinkMeet = () => {
    navigator.clipboard.writeText(reservation.room_link);
    return toast.success("Link berhasil disalin");
  };

  const fetchUpdateReservation = async (event: any) => {
    updateReservation({
      reservationCode: reservation.reservation_code,
      roomLink: event.conferenceData.entryPoints[0].uri,
      eventId: event.id,
    }).then((val) => {
      toast.success("Success Add To Calendar");
      window.location.reload();
    });
  };

  const [loadingAddCalendar, setLoadingAddCalendar] = useState(false);
  const addToCalendar = () => {
    setLoadingAddCalendar(true);

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        setLoadingAddCalendar(false);
        createEvent();
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
      });
  };

  const createEvent = () => {
    var _start = moment(reservation.date + " " + reservation.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end = moment(reservation.date + " " + reservation.time_to).format(
      "yyyy-MM-DD HH:mm"
    );
    let events = {
      summary: "Consultasion Session 60 minutes",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: reservation.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: reservation.member_timezone.title,
      },
      attendees: [
        {
          email: reservation.member_email,
          responseStatus: "needsAction",
          displayName: reservation.member_username,
        },
        {
          email: reservation.psycholog_email,
          responseStatus: "needsAction",
          displayName: reservation.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: reservation.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .createEventWithVideoConference(events, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        console.log(data);
        fetchUpdateReservation(data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Failed Add To Calendar");
        console.log(error);
      });
  };

  return (
    <div>
      <Stepper step={3}></Stepper>
      <div className="max-w-2xl mx-auto">
        <div className="p-8 bg-white rounded-xl">
          <div className="max-w-lg mx-auto">
            <div className="text-center">
              <Title>You have successfully booked a schedule</Title>
            </div>
            <div className="flex justify-center my-8">
              <div className="bg-blue-background p-6 rounded-full">
                <TickCircle
                  className="text-brand-dark"
                  variant="Bold"
                  size={46}
                ></TickCircle>
              </div>
            </div>
            <div className="flex flex-col items-center text-lg mb-8">
              <div>You will receive a booking receipt to</div>
              <div>
                <span className="font-medium">{reservation.member_email}</span>{" "}
                shortly.
              </div>
            </div>
            <Title>Detail Pemesanan</Title>
            <Link
              to={`${config.HOME_URL}/booking/${reservation.psycholog_code}`}
              target="_top"
            >
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16"
                    src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <div className="text-xl font-medium mb-1">
                      {reservation.psycholog_username}
                    </div>
                    <div className="text-neutral-secondary">
                      {reservation.psycholog_title}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Janji Temu
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === IN_PERSON_INDIVIDUAL
                        ? "Face to face (Individual)"
                        : reservation.format_call === IN_PERSON_COUPLE
                        ? "Face to face (Couple)"
                        : reservation.format_call === IN_PERSON_FAMILY
                        ? "Face to face (Family)"
                        : reservation.format_call === IN_PERSON_CHILD
                        ? "Face to face (Child)"
                        : reservation.format_call === IN_PERSON_HYPNOTHERAPY
                        ? "Face to face (Hypnotherapy)"
                        : reservation.format_call === IN_PERSON_SI_THERAPY
                        ? "Face to face (SI Therapy)"
                        : reservation.format_call === IN_PERSON_SPEECH_THERAPY
                        ? "Face to face (Speech Therapy)"
                        : reservation.format_call === ONLINE_CALL_INDIVIDUAL
                        ? "Online Call (Individual)"
                        : "Online Call (Couple)"}{" "}
                      60 minutes
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Lokasi
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === ONLINE_CALL_INDIVIDUAL ||
                      reservation.format_call === ONLINE_CALL_COUPLE
                        ? `Online`
                        : `${reservation.location_detail.name} - ${reservation.location_detail.address}`}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Tanggal
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.date).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Waktu
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
                    </div>
                  </div>

                  { reservation.room_detail && <div>
                    <div className="font-medium text-neutral-secondary">
                      Ruang
                    </div>
                    <div className="text-neutral-800">
                      { reservation.room_detail.room_name }
                    </div>
                  </div> }

                </div>
              </div>
            </Link>

            <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Subtotal</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.amount)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Promo</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.promo_used)}
                </div>
              </div>
            </div>

            <div className="flex justify-between text-xl font-medium text-black-app mt-6">
              <div>Total</div>
              <div>{utils.currencyFormatter(total)}</div>
            </div>
          </div>
        </div>
        <div className="p-8 bg-white rounded-xl flex items-center flex-col justify-center mt-8">
          <p className="text-xl font-bold text-custom-black text-center">
            Tambahkan sesimu ke Google Kalender
          </p>
          <img
            className="h-[100px] w-[100px] mt-5 mb-2"
            src="/images/svg/ic_calendar.svg"
            alt=""
          />
          <div>
            {reservation.room_link === "" ? (
              <div>
                <p className="text-sm md:text-base  text-center mt-2">
                  To receive a Google Meet link, please
                  <br />
                  <b>add your session to Google Calendar</b>
                  <br />
                  before your session starts.
                </p>

                <div
                  className="flex items-center justify-between border border-custom-border rounded-full p-4 mt-3 cursor-pointer w-[300px]"
                  onClick={() => addToCalendar()}
                >
                  <img
                    className="h-[18px] w-[18px]"
                    src="/images/svg/ic_calendar_google.svg"
                    alt=""
                  />
                  <p className="text-sm font-medium">Add to Calendar</p>
                  <span className="w-[20px]"></span>
                </div>
              </div>
            ) : (
              <div>
                <p className="text-sm md:text-base text-center mt-2">
                  Google Meet link
                </p>

                <div className="flex items-center gap-4 mt-2">
                  <p
                    className="text-base text-custom-blue font-medium cursor-pointer"
                    onClick={() => onJoinGMeet(reservation.room_link)}
                  >
                    {reservation.room_link}
                  </p>

                  <img
                    className="h-[32px] w-[32px] cursor-pointer"
                    src="/images/svg/ic_copy.svg"
                    alt=""
                    onClick={onCopyLinkMeet}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="font-medium text-2xl mt-12 mb-6 text-custom-blue">
          Before your session starts, please do the following:
        </div>
        <TitleStep order="1">
          <div className="font-medium text-xl">Isi Registration Form</div>
        </TitleStep>
        <div>
          <p className="text-lg text-custom-black font-normal mt-3">
            Adult Intake Form: TBD
            <br />
            Children Intake Form: TBD
            <br />
            Recruitment Assessment Form: TBD
            <br />
            Aptitude Test Form: TBD
            <br />
          </p>
          <div className="bg-white border rounded-[20px] my-6 ">
            <div className="w-full py-6 border-b-2 font-medium flex items-center justify-center">
              <p>Adult Intake Form:</p>
              <Link
                to={"http://bit.ly/ohana_clientintakeform"}
                target="_blank"
                className="text-custom-blue ml-2"
              >
                Klik Disini
              </Link>
            </div>
            <div className="w-full py-6 border-b-2 font-medium flex items-center justify-center">
              <p>Children Intake Form:</p>
              <Link
                to={"http://bit.ly/ohana_childteensintakeform"}
                target="_blank"
                className="text-custom-blue ml-2"
              >
                Klik Disini
              </Link>
            </div>
            <div className="w-full py-6 border-b-2 font-medium flex items-center justify-center">
              <p>Recruitment Assessment Form:</p>
              <Link
                to={"http://bit.ly/osc_intakeform"}
                target="_blank"
                className="text-custom-blue ml-2"
              >
                Klik Disini
              </Link>
            </div>
            <div className="w-full py-6 font-medium flex items-center justify-center">
              <p>Aptitude Test Form:</p>
              <Link
                to={"http://bit.ly/ohana_aptitudeform"}
                target="_blank"
                className="text-custom-blue ml-2"
              >
                Klik Disini
              </Link>
            </div>
          </div>
        </div>

        <TitleStep order="2">
          <div className="font-medium text-xl">Isi Current Condition Form</div>
        </TitleStep>
        <div>
          <p className="text-lg text-custom-black font-normal mt-3">
            Current Condition Form: TBD
          </p>
          <div className="bg-white border rounded-[20px] my-6 ">
            <div className="w-full py-6 font-medium flex items-center justify-center">
              <p>Current Condition Form:</p>
              <Link
                to={"https://bit.ly/OSKondisiKlien"}
                target="_blank"
                className="text-custom-blue ml-2"
              >
                Klik Disini
              </Link>
            </div>
          </div>
        </div>

        {/* <TitleStep order="3">
          <div className="font-medium text-xl">
            Add your session to Google Calendar.{" "}
            <span
              className="font-semibold text-brand-dark cursor-pointer"
              onClick={() => addToCalendar()}
            >
              Click here
            </span>{" "}
            to add your session.
          </div>
        </TitleStep> */}
      </div>

      {loadingAddCalendar ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-white/60">
            <div className="absolute w-full my-6 mx-auto md:max-w-lg">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="px-10 py-6 w-full">
                  <Loading></Loading>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

function TitleStep(props: { children: any; order: string }) {
  return (
    <div className="flex items-start gap-4">
      <div className="rounded-full h-8 w-8 bg-brand-dark shrink-0 text-white flex justify-center items-center text-lg font-medium">
        <div>{props.order}</div>
      </div>
      {props.children}
    </div>
  );
}
