import Skeleton from "@mui/material/Skeleton";
import React from "react";

const SkeletonPayment = () => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
        <div className="col-span-3">
          <SkeletonBookPayment />
        </div>
        <div className="col-span-2 order-first sm:order-last">
          <SkeletonBookProfile />
        </div>
      </div>
    </div>
  );
};

const SkeletonBookPayment = () => {
  return (
    <div className="rounded-xl sm:bg-white sm:p-6">
      <Skeleton
        width={140}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="grid grid-cols-3 gap-4 mt-4">
        <SkeletonBookCardSessionType />
        <SkeletonBookCardSessionType />
        <SkeletonBookCardSessionType />
      </div>
      <Skeleton
        width={140}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "16px",
        }}
      />
      <Skeleton
        height={216}
        variant="rounded"
        style={{
          borderRadius: "10px",
          marginTop: "10px",
        }}
      />
      <Skeleton
        width={140}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "16px",
        }}
      />
      <Skeleton
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "16px",
        }}
      />
      <Skeleton
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "8px",
        }}
      />
      <Skeleton
        width={100}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "24px",
        }}
      />
      <div className="grid grid-cols-3 gap-4 mt-2">
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
      </div>
      <Skeleton
        width={100}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "24px",
        }}
      />
      <div className="grid grid-cols-3 gap-4 mt-2">
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
      </div>
      <Skeleton
        width={100}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "24px",
        }}
      />
      <div className="grid grid-cols-3 gap-4 mt-2">
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
        <SkeletonBookCardTime />
      </div>
    </div>
  );
};

const SkeletonBookCardSessionType = () => {
  return (
    <div className="border rounded-lg p-2 flex gap-2 items-center">
      <Skeleton
        width={60}
        height={60}
        variant="rounded"
        className="min-w-[60px]"
        style={{
          borderRadius: "60px",
        }}
      />
      <div className="w-full">
        <Skeleton
          height={16}
          variant="rounded"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          width={60}
          height={16}
          variant="rounded"
          style={{
            borderRadius: "30px",
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );
};

const SkeletonBookCardTime = () => {
  return (
    <div className="border rounded-lg p-2 flex gap-2 items-center">
      <Skeleton
        width={200}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "10px",
        }}
      />
    </div>
  );
};

const SkeletonBookProfile = () => {
  return (
    <div className="rounded-xl sm:bg-white sm:p-6">
      <Skeleton
        width={100}
        height={24}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="border rounded-lg p-2 flex gap-2 items-center mt-3">
        <Skeleton
          width={60}
          height={60}
          variant="rounded"
          className="min-w-[60px]"
          style={{
            borderRadius: "60px",
          }}
        />
        <div className="w-full">
          <Skeleton
            height={16}
            variant="rounded"
            style={{
              borderRadius: "30px",
            }}
          />
          <Skeleton
            width={60}
            height={16}
            variant="rounded"
            style={{
              borderRadius: "30px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonPayment;
