import api from "./api";

export const fetchTargets = (params: { lang: string }) => {
  return api
    .get("/v1/members/specialist/targets", {
      params: {
        is_active: true,
        lang: params.lang,
      },
    })
    .then((val) => val.data);
};
