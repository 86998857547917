import Skeleton from "@mui/material/Skeleton";
import React from "react";

const SkeletonProfile = () => {
  return (
    <div>
      <div className="flex flex-col -mx-4 md:gap-8 md:mx-auto">
        <SkeletonProfileHeader />
        <SkeletonProfileBio />
        <SkeletonProfileExpertise />
        <SkeletonProfileApproach />
        <SkeletonProfileTarget />
        <SkeletonProfileUniversity />
        <SkeletonProfileLicense />
        <SkeletonProfileReview />
        <SkeletonProfilePrice />
      </div>
    </div>
  );
};

const SkeletonProfileHeader = () => {
  return (
    <div className="rounded-xl bg-white overflow-hidden">
      <img
        className="h-36 w-full object-cover"
        src="/images/svg/bg-header-skeleton.svg"
        alt=""
      />
      <div className="flex flex-col items-center md:flex-row">
        <div className="md:ml-12 md:items-start">
          <div className="absolute -translate-y-20 -translate-x-1/2 z-10 md:-translate-y-44 md:translate-x-0">
            <div className="rounded-full flex items-center justify-center overflow-hidden w-48 h-48 bg-white">
              <div className="rounded-full w-44 h-44 bg-neutral-background" />
            </div>
          </div>
        </div>
        <div className="p-6 flex-col text-center md:text-left mt-24 md:mt-0 md:ml-48">
          <Skeleton
            width={400}
            height={24}
            variant="rounded"
            style={{
              borderRadius: "30px",
            }}
          />
          <Skeleton
            width={200}
            height={16}
            variant="rounded"
            style={{
              borderRadius: "30px",
              marginTop: "10px",
            }}
          />
          <Skeleton
            width={100}
            height={16}
            variant="rounded"
            style={{
              borderRadius: "30px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const SkeletonProfileBio = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={100}
        height={24}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <Skeleton
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
      <Skeleton
        width={200}
        height={16}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
    </div>
  );
};

const SkeletonProfileExpertise = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={150}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
      <div className="hidden md:grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
    </div>
  );
};

const SkeletonProfileApproach = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={150}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
      <div className="hidden md:grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
    </div>
  );
};

const SkeletonProfileTarget = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={150}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
      <div className="hidden md:grid grid-cols-4 gap-4 mt-3">
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          height={36}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
    </div>
  );
};

const SkeletonProfileUniversity = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={160}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <Skeleton
        width={240}
        height={12}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
      <Skeleton
        width={300}
        height={12}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
      <Skeleton
        width={160}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "24px",
        }}
      />
      <Skeleton
        width={240}
        height={12}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
      <Skeleton
        width={300}
        height={12}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
    </div>
  );
};

const SkeletonProfileLicense = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={160}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <Skeleton
        width={240}
        height={12}
        variant="rounded"
        style={{
          borderRadius: "30px",
          marginTop: "10px",
        }}
      />
    </div>
  );
};

const SkeletonProfileReview = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <div className="flex justify-between">
        <Skeleton
          width={160}
          height={20}
          variant="rounded"
          style={{
            borderRadius: "30px",
          }}
        />
        <Skeleton
          width={160}
          height={20}
          variant="rounded"
          style={{
            borderRadius: "30px",
          }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={200}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
      </div>
    </div>
  );
};

const SkeletonProfilePrice = () => {
  return (
    <div className="rounded-xl bg-white p-6">
      <Skeleton
        width={160}
        height={20}
        variant="rounded"
        style={{
          borderRadius: "30px",
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
        <Skeleton
          height={70}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
        <Skeleton
          height={70}
          variant="rounded"
          className="col-span-1"
          style={{
            borderRadius: "8px",
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonProfile;
