import Skeleton from "@mui/material/Skeleton";
import React from "react";

const SkeletonSpecialist = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-10 bg-white px-8 py-4 rounded-lg gap-4">
      <div className="col-span-2 flex justify-center sm:justify-start">
        <div className="rounded-full w-[130px] h-[130px] bg-neutral-background"></div>
      </div>
      <div className="col-span-5 items-start pr-8 pb-8 sm:pb-0 border-b sm:border-b-0 md:border-r">
        <Skeleton
          height={30}
          variant="rounded"
          style={{
            width: "100%",
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <Skeleton
          height={20}
          variant="rounded"
          style={{
            width: "80%",
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <Skeleton
          width={80}
          height={20}
          variant="rounded"
          style={{
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <Skeleton
          height={30}
          variant="rounded"
          style={{
            width: "100%",
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <Skeleton
          height={20}
          variant="rounded"
          style={{
            width: "80%",
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <Skeleton
          width={80}
          height={20}
          variant="rounded"
          style={{
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <div className="flex items-center gap-3 mt-3">
          <div className="bg-neutral-background w-[80px] h-10 p-3 rounded-full">
            <Skeleton
              variant="rounded"
              style={{
                width: "100%",
                borderRadius: "30px",
              }}
            />
          </div>
          <div className="bg-neutral-background w-[80px] h-10 p-3 rounded-full">
            <Skeleton
              variant="rounded"
              style={{
                width: "100%",
                borderRadius: "30px",
              }}
            />
          </div>
          <div className="bg-neutral-background w-[80px] h-10 p-3 rounded-full">
            <Skeleton
              variant="rounded"
              style={{
                width: "100%",
                borderRadius: "30px",
              }}
            />
          </div>
          <div className="bg-neutral-background w-[80px] h-10 p-3 rounded-full">
            <Skeleton
              variant="rounded"
              style={{
                width: "100%",
                borderRadius: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-span-3 p-4 flex flex-col items-center justify-center gap-4">
        <Skeleton
          height={30}
          variant="rounded"
          style={{
            width: "100%",
            borderRadius: "30px",
            marginTop: "0.5rem",
          }}
        />
        <div className="border w-full p-3 rounded-full">
          <Skeleton
            variant="rounded"
            style={{
              width: "100%",
              borderRadius: "30px",
            }}
          />
        </div>
        <div className="bg-neutral-background w-full p-3 rounded-full">
          <Skeleton
            variant="rounded"
            style={{
              width: "100%",
              borderRadius: "30px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SkeletonSpecialist;
