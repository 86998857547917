import Skeleton from "@mui/material/Skeleton";
import React from "react";
import SkeletonFilterMenu from "./SkeletonFilterMenu";
import Divider from "@mui/material/Divider";
import SkeletonSpecialist from "./SkeletonSpecialist";

const SkeletonBooking = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <Skeleton width={240} height={40} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-7 gap-8 mt-4">
        <div className="hidden md:block md:col-span-2 bg-white px-8 py-4 rounded-lg">
          <Skeleton width={100} height={40} />
          <div className="my-5">
            <Divider />
          </div>
          <SkeletonFilterMenu />
          <SkeletonFilterMenu />
          <SkeletonFilterMenu />
        </div>
        <div className="md:col-span-5 flex flex-col gap-8">
          <SkeletonSpecialist />
          <SkeletonSpecialist />
          <SkeletonSpecialist />
          <SkeletonSpecialist />
        </div>
      </div>
    </div>
  );
};

export default SkeletonBooking;
