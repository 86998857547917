import { useGoogleLogin } from "@react-oauth/google";
import { RegisterForm } from "components/auth/Register";
import OtpInput from "components/cotp";
import { config } from "helper/config";
import {
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  authLoginSosmed,
  authRegistration,
  authRegistrationSosmed,
  checkUserGoogle,
  requestOTP,
  verifyEmail,
} from "service/auth";
import {
  getBirthyear,
  getCountry,
  getEducationLevel,
  getGender,
  getMarital,
  getReligion,
} from "service/global";
import { updateProfile } from "service/profile";
import { CapitalizeFirstLetter } from "utils";
import validator from "validator";

export function Register() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [birthyear, setBirthyear] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [marital, setMarital] = useState("");

  const [countries, setCountries] = useState<any[]>([]);
  const [birthyears, setBirthyears] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [educationLevels, setEducationLevels] = useState<any[]>([]);
  const [religions, setReligions] = useState<any[]>([]);
  const [maritals, setMaritals] = useState<any[]>([]);

  useEffect(() => {
    getCountry().then((val) => {
      setCountries(val.data ?? []);
    });

    getBirthyear().then((val) => {
      setBirthyears(val ?? []);
    });

    getGender({ lang: "id" }).then((val) => {
      setGenders(val ?? []);
    });

    getEducationLevel({ lang: "id" }).then((val) => {
      setEducationLevels(val.data);
    });

    getReligion({ lang: "id" }).then((val) => {
      setReligions(val.data);
    });

    getMarital({ lang: "id" }).then((val) => {
      setMaritals(val ?? []);
    });
  }, []);

  const isValidAuth = () => {
    const validPassword = password.length > 6;
    const validName = name.length > 3;
    const validEmail = validator.isEmail(email);
    const validData =
      phone.length > 10 &&
      country !== "" &&
      birthyear !== "" &&
      gender !== "" &&
      educationLevel !== "" &&
      religion !== "";

    return validName && validPassword && validEmail && validData;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const onSubmit = async () => {
    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    setIsLoading(true);

    try {
      // Register First
      const res = await authRegistration({
        email: email,
        password: password,
      });
      const token = res.data.token;

      setTokenAuth(token);

      setTimeout(async () => {
        const resProfile = await updateProfile({
          profile_url: "",
          username: name,
          phone: phone,
          email: email,
          gender_key: gender,
          birthyear: birthyear,
          marital: marital,
          country: country,
          religion_id: religion,
          education_level_id: educationLevel,
          is_register: true,
        });

        const data = resProfile.data;
        setNameCookies(data.username);
        setRoleCookies("member");
        setUserData({
          username: data.username,
          email: data.email,
          profile_url: data.profile_url,
          role_name: data.role_name,
        });
        setIsLoading(false);
        requestOTP().then((res) => {
          setCanResend(false);
          setShowVerifyOTP(true);
          setTimeLeft(30);
        });
      }, 200);
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      registerGoogle(
        "gmail",
        tokenResponse.access_token,
        userInfo.email,
        userInfo.name
      );
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const registerGoogle = async (
    type: string,
    token: string,
    emailGoogle: string,
    nameGoogle: any
  ) => {
    setIsLoadingGoogle(true);
    authRegistrationSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
      code: "",
    })
      .then((res) => {
        const data = res.data;
        const token = data.token;
        setTokenAuth(token);

        updateProfile({
          profile_url: "",
          username: nameGoogle,
        }).then((val) => {
          const updatedData = val.data;

          setNameCookies(updatedData.username);
          setRoleCookies(updatedData.role_name);

          setUserData({
            member_code: updatedData.member_code,
            username: updatedData.username,
            email: updatedData.email,
            profile_url: updatedData.profile_url,
            role_name: updatedData.role_name,
          });

          setIsLoadingGoogle(false);
          requestOTP().then((res) => {
            setCanResend(false);
            setShowVerifyOTP(true);
            setTimeLeft(30);
          });
        });
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        if (err.toString().toLowerCase().includes("email already exist")) {
          loginGoogle("gmail", token, emailGoogle);
        } else {
          toast.warn(CapitalizeFirstLetter(err));
        }
      });
  };

  const loginGoogle = async (type: string, token: string, emailGoogle: any) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;

        setTokenAuth(token);
        setNameCookies(data.username);
        setRoleCookies("member");
        const redirectUrl = searchParams.get("redirect_url");
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/booking");
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const onResend = async () => {
    requestOTP().then((res: any) => {
      setTimeLeft(30);
      setCanResend(false);
    });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyEmail({
      pin: otp,
    })
      .then((res: any) => {
        setIsLoadingVerify(false);

        const redirectUrl = searchParams.get("redirect_url");
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("/booking");
        }
      })
      .catch((err: string) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="max-w-2xl mx-auto px-4 my-16">
      <div className="font-medium text-xl mb-12 text-center">
        Daftar akun Anda untuk melanjutkan
      </div>
      {showVerifyOTP ? (
        <div className="border-0 rounded-lg shadow-lg flex justify-center w-full bg-white focus:outline-none">
          <div className="text-center px-20 py-16">
            <p className="text-lg font-semibold">Verifikasi email Anda</p>
            <p className="text-sm font-normal text-text-grey mt-4">
              Silakan masukkan 4 Digit kode yang dikirimkan ke
            </p>
            <p className="text-sm font-medium text-text-grey mb-6">{email}</p>
            <div className="flex justify-center">
              <OtpInput value={otp} valueLength={4} onChange={onChangeOTP} />
            </div>
            <div className="mt-6">
              {canResend ? (
                <div className="flex justify-center">
                  <p className="text-sm text-text-grey">Tidak menerima kode?</p>
                  <p
                    className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                    onClick={() => {
                      onResend();
                    }}
                  >
                    Kirim Ulang Kode
                  </p>
                </div>
              ) : (
                <p className="text-sm text-text-grey">
                  {"Harap tunggu " +
                    timeLeft +
                    " detik untuk mengirim ulang kode"}
                </p>
              )}
            </div>
            <button
              type="button"
              disabled={isBtnVerifyDisabled}
              className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-10 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
              onClick={() => onVerify()}
            >
              <strong>
                {isLoadingVerify ? "Memverifikasi..." : "Verifikasi"}
              </strong>
              {isLoadingVerify ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      ) : (
        <RegisterForm
          isValidInput={isValidAuth()}
          name={name}
          email={email}
          password={password}
          country={country}
          birthyear={birthyear}
          gender={gender}
          religion={religion}
          educationLevel={educationLevel}
          marital={marital}
          phone={phone}
          countries={countries}
          birthyears={birthyears}
          genders={genders}
          religions={religions}
          educationLevels={educationLevels}
          maritals={maritals}
          isLoading={isLoading}
          isLoadingGoogle={isLoadingGoogle}
          setAuthType={(val) => navigate(`/booking/login`)}
          setName={setName}
          setEmail={setEmail}
          setPassword={setPassword}
          setCountry={setCountry}
          setGender={setGender}
          setBirthyear={setBirthyear}
          setMarital={setMarital}
          setEducationLevel={setEducationLevel}
          setReligion={setReligion}
          setPhone={setPhone}
          onSubmit={onSubmit}
          onSubmitGoogle={onSubmitGoogle}
        ></RegisterForm>
      )}
    </div>
  );
}
