import { MenuItem, Select } from "@mui/material";
import { ButtonBlue } from "components/Button";
import { InputText } from "components/Input";
import ShowHidePassword from "components/showHidePassword";
import { config } from "helper/config";

export function RegisterForm(props: {
  name: string;
  email: string;
  password: string;
  country: string;
  birthyear: string;
  gender: string;
  educationLevel: string;
  religion: string;
  marital: string;
  phone: string;
  countries: any;
  birthyears: any;
  genders: any;
  educationLevels: any;
  religions: any;
  maritals: any;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  isValidInput: boolean;
  setName: (_: string) => void;
  setEmail: (_: string) => void;
  setPassword: (_: string) => void;
  setCountry: (_: string) => void;
  setBirthyear: (_: string) => void;
  setGender: (_: string) => void;
  setEducationLevel: (_: string) => void;
  setReligion: (_: string) => void;
  setMarital: (_: string) => void;
  setPhone: (_: string) => void;
  setAuthType: (_: string) => void;
  onSubmit: () => void;
  onSubmitGoogle: () => void;
}) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">Daftar</div>
      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="name">
            Nama Lengkap*
          </label>
        </div>
        <InputText
          placeholder="Nama Lengkap"
          onChange={(val) => props.setName(val)}
          defaultValue={props.name}
          type="text"
          id="name"
        ></InputText>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="email">
            Email*
          </label>
        </div>
        <InputText
          placeholder="Email"
          onChange={(val) => props.setEmail(val)}
          defaultValue={props.email}
          type="email"
          id="email"
        ></InputText>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Phone Number*
          </label>
        </div>
        <InputText
          placeholder="Your phone number"
          onChange={(val) => props.setPhone(val)}
          defaultValue={props.phone}
          type="tel"
          id="phone"
        ></InputText>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Negara*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.country}
          onChange={(e) => props.setCountry(e.target.value as string)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 14,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Negara</em>
          </MenuItem>
          {props.countries.map((item: any) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Tahun Lahir*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.birthyear}
          onChange={(e) => props.setBirthyear(e.target.value as string)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 16,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Tahun lahir</em>
          </MenuItem>
          {props.birthyears.map((item: any, index: any) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Jenis Kelamin*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.gender}
          onChange={(e) => props.setGender(e.target.value as string)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 16,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Jenis Kelamin</em>
          </MenuItem>
          {props.genders.map((item: any) => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Status Pernikahan*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.marital}
          onChange={(e) => props.setMarital(e.target.value as string)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 16,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Marital Status</em>
          </MenuItem>
          {props.maritals.map((item: any) => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Tingkat Pendidikan*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.educationLevel}
          onChange={(e) => props.setEducationLevel(e.target.value)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 16,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Tingkat Pendidikan</em>
          </MenuItem>
          {props.educationLevels.map((item: any, index: any) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label
            className="text-lg font-medium text-black-app"
            htmlFor="religion"
          >
            Agama*
          </label>
        </div>
        <Select
          displayEmpty
          value={props.religion}
          onChange={(e) => props.setReligion(e.target.value)}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            marginTop: "8px",
            width: "100%",
            fontSize: 16,
          }}
        >
          <MenuItem value={""}>
            <em className="text-slate-400">Agama</em>
          </MenuItem>
          {props.religions.map((item: any, index: any) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <div className="mb-2">
          <label
            className="text-lg font-medium text-black-app"
            htmlFor="password"
          >
            Password*
          </label>
        </div>
        <ShowHidePassword
          name={props.password}
          defaultValue={props.password}
          placeholder="Password Anda (8+ characters)"
          onChange={(val) => {
            props.setPassword(val);
          }}
        />
      </div>

      <div className="flex flex-row	items-center">
        <div className="flex-1 h-px bg-custom-border" />
        <div>
          <p className="w-auto px-4 text-base text-gray-400">atau</p>
        </div>
        <div className="flex-1 h-px bg-custom-border" />
      </div>
      <button
        type="button"
        className="text-white px-4 flex items-center justify-center bg-white border border-solid border-custom-border hover:bg-blue/10 rounded-full w-full h-12 mt-2"
        onClick={props.onSubmitGoogle}
      >
        {props.isLoadingGoogle ? (
          <div
            className="h-5 w-5 animate-spin rounded-full border-4 border-solid border-blue border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : (
          <img
            className="h-[24px] w-[24px] cursor-pointer mr-4"
            src="/images/svg/ic_google.svg"
            alt=""
          />
        )}
        <span className="text-black font-medium">
          {props.isLoadingGoogle
            ? "Tunggu Sebentar..."
            : "Daftar dengan Google"}
        </span>
      </button>

      <div className="font-medium mt-8">
        Sudah punya akun?{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={() => props.setAuthType(config.constant.login)}
        >
          Masuk
        </span>
      </div>

      <ButtonBlue
        className={`px-6 py-2 ${props.isValidInput ? "" : "bg-slate-300"}`}
        onClick={props.onSubmit}
      >
        Daftar
      </ButtonBlue>
    </div>
  );
}
