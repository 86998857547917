import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "pages/auth/Login";
import { Register } from "pages/auth/Register";
import Search from "pages/search";
import Profile from "pages/profile";
import Booking from "pages/booking";
import Payment from "pages/payment";
import Reservation from "pages/reservation";
import ReservationOvertime from "pages/overtime";

export default function RoutePage() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/booking"></Navigate>}></Route>
      <Route path="/booking/login" element={<Login></Login>}></Route>
      <Route path="/booking/register" element={<Register></Register>}></Route>
      <Route path="/booking" element={<Search></Search>} />
      <Route
        path="/booking/profile/:member_code"
        element={<Profile></Profile>}
      />
      <Route path="/booking/:member_code" element={<Booking></Booking>} />
      <Route
        path="/booking/:specialist_code/:schedule_detail_code"
        element={<Payment></Payment>}
      />
      <Route
        path="/booking/reservation/:reservation_code"
        element={<Reservation></Reservation>}
      />
      <Route
        path="/booking/reservation/:reservation_code/overtime"
        element={<ReservationOvertime></ReservationOvertime>}
      />
    </Routes>
  );
}
