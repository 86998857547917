import api from "./api";

export const getReservation = (params: { reservationCode: string }) => {
  return api
    .get(`/v1/members/specialist/reservation/${params.reservationCode}`)
    .then((val) => val.data);
};

export const bookReservation = (params: {
  specialistCode: string;
  scheduleDetailCode: string;
  formatCall: string;
  location: string;
  paymentType: string;
  paymentChannel: string;
  paymentOption: string;
  paymentPhone?: string;
  promoCode?: string;
  promoApplied?: number;
}) => {
  return api
    .post(
      `/v1/members/specialist/${params.specialistCode}/schedule/${params.scheduleDetailCode}/reservation`,
      {
        format_call: params.formatCall,
        location_code: params.location,
        payment_type: params.paymentType,
        payment_channel: params.paymentChannel,
        payment_option: params.paymentOption,
        payment_phone: params.paymentPhone,
        promo_code: params.promoCode,
        promo_applied: params.promoApplied,
      }
    )
    .then((val) => val.data);
};
export const bookReservationV2 = (params: {
  specialistCode: string;
  scheduleDetailCode: string;
  formatCall: string;
  location: string;
  paymentType: string;
  paymentChannel: string;
  paymentOption: string;
  paymentPhone?: string;
  promoCode?: string;
  promoApplied?: number;
}) => {
  return api
    .post(
      `/v1/members/specialist/${params.specialistCode}/schedule/${params.scheduleDetailCode}/reservation_v2`,
      {
        format_call: params.formatCall,
        location_code: params.location,
        payment_type: params.paymentType,
        payment_channel: params.paymentChannel,
        payment_option: params.paymentOption,
        payment_phone: params.paymentPhone,
        promo_code: params.promoCode,
        promo_applied: params.promoApplied,
      }
    )
    .then((val) => val.data);
};

export const fetchCounseling = (params: { type: string }) => {
  return api
    .get("/v1/members/counseling", {
      params: {
        type: params.type,
        is_active: true,
        lang: "id",
        limit: 100,
      },
    })
    .then((val) => val.data);
};

export const createCounselingAnswer = (reservationCode: string, data: any) => {
  return api
    .post(
      `/v1/members/specialist/reservation/${reservationCode}/counseling`,
      data
    )
    .then((val) => val.data);
};

export const getCounselingAnswer = (reservationCode: string) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${reservationCode}/counseling/answer`
    )
    .then((val) => val.data);
};

export const putReschedule = async (params: {
  reservationCode: string;
  scheduleCode: string;
}) => {
  const res = await api.put(
    `/v1/members/specialist/reservation/${params.reservationCode}/reschedule`,
    {
      schedule_detail_code: params.scheduleCode,
      reschedule_reason: "",
    }
  );
  return res.data;
};

export const updateReservation = async (params: {
  reservationCode: string;
  roomLink: string;
  eventId: string;
}) => {
  const res = await api.put(
    `/v1/members/specialist/reservation/${params.reservationCode}`,
    {
      room_link: params.roomLink,
      event_id: params.eventId,
    }
  );
  return res.data;
};
