export function BadgeFilter(props: { title: string; onClose: () => void }) {
  return (
    <div
      className="bg-white px-3 py-2 flex text-sm rounded-lg font-medium items-center"
      style={{
        color: "#4E5259",
      }}
    >
      <div>{props.title}</div>
      <div className="ml-4 cursor-pointer" onClick={props.onClose}>
        <img src="/images/svg/close-x.svg" alt="" />
      </div>
    </div>
  );
}
