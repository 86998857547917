import { ArrowDown2, ArrowRight2 } from 'iconsax-react';
import { useState } from 'react';
import utils from 'utils';

export function SearchExpandable(props: {
    children: any;
    title: string;
}) {
    const [isOpen, setOpen] = useState(true);

    return (
        <div>
            <div className='flex py-2 justify-between cursor-pointer' onClick={() => setOpen(!isOpen)}>
                <div className='font-medium'>{props.title}</div>
                {
                    isOpen ? (
                        <ArrowDown2 size={18} variant='Linear'></ArrowDown2>
                    ) : (
                        <ArrowRight2 size={18} variant='Linear'></ArrowRight2>
                    )
                }
            </div>
            <div className={`${isOpen ? '' : 'hidden'}`}>
                {props.children}
            </div>
        </div>
    );
}

export function CategoryItem(props: {
    value: any;
    expertises: string[];
    isOpen: boolean;
    onChangeExpertise: (val: string) => void;
    onClick?: () => void;
}) {
    const value = props.value;

    return (
        <div className='ml-4 overflow-hidden'>
            <div className='flex py-2 justify-between cursor-pointer select-none' onClick={props.onClick}>
                <div className='font-medium text-sm'>{utils.onlyAlphanum(value.category.name)}</div>
                {
                    props.isOpen ? (
                        <ArrowDown2 size={16} variant='Linear'></ArrowDown2>
                    ) : (
                        <ArrowRight2 size={16} variant='Linear'></ArrowRight2>
                    )
                }
            </div>
            <div className={`${!props.isOpen ? 'h-0' : ''}`}>
                {
                    value.expertises.map((val: any) => {
                        const expertiseIndex = props.expertises.findIndex(v => val.expertise_code === v);
                        const expertiseActive = expertiseIndex !== -1;

                        return (
                            <ExpertiseItem
                                value={val}
                                isActive={expertiseActive}
                                onClick={() => props.onChangeExpertise(val.expertise_code)}
                                key={`${Math.random()}`}
                            ></ExpertiseItem>
                        );
                    })
                }
            </div>
        </div>
    );
}

export function ExpertiseItem(props: {
    value: any;
    isActive?: boolean;
    onClick: () => void;
}) {
    const value = props.value;

    return (
        <div className='py-2 flex ml-4'>
            <input id={value.expertise_code}
                type="checkbox" checked={props.isActive}
                onChange={props.onClick}
                className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
            <label htmlFor={value.expertise_code} className='ml-4 text-sm select-none cursor-pointer'>{value.name}</label>
        </div>
    );
}

export function CheckboxItem(props: {
    id?: string;
    isActive?: boolean;
    title?: string;
    onChange: () => void;
}) {
    return (
        <div className='py-2 flex ml-4'>
            <input id={props.id}
                type="checkbox" checked={props.isActive}
                onChange={props.onChange}
                className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
            <label htmlFor={props.id} className='ml-4 text-sm select-none cursor-pointer'>{props.title}</label>
        </div>
    );
}
