import { BadgeFilter } from "components/BadgeFilter";
import { ButtonBlack } from "components/Button";
import { InputSearch } from "components/Input";
import { SpecialistItem } from "components/Specialist";
import { CheckboxItem, SearchExpandable } from "components/search";
import SkeletonBooking from "components/skeleton/SkeletonBooking";
import { Add, SearchNormal1, Setting4 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getExpertises } from "service/expertise";
import { getSpecialists } from "service/specialist";
import { fetchTargets } from "service/targets";
import { fetchTitle } from "service/title";

const ListSortby = [
  {
    value: "fastest_available",
    title: "Ketersediaan Tercepat",
  },
  {
    value: "rating",
    title: "Rating Tertinggi",
  },
  {
    value: "price_asc",
    title: "Harga Terendah ke Tertinggi",
  },
  {
    value: "price_desc",
    title: "Harga Tertinggi ke Terendah",
  },
  {
    value: "yoe",
    title: "Tahun Pengalaman",
  },
];

const listStatusTeam = [
  {
    value: "core",
    title: "Tim Inti",
  },
  {
    value: "associate",
    title: "Tim Mitra",
  },
];

function splitString(val: string): string[] {
  if (val === "") {
    return [];
  }

  return val.split(",");
}

export default function Search() {
  const [params] = useSearchParams();

  const [notFound, setNotFound] = useState(false);

  // Properties
  const [filterExpertises, setFilterExpertises] = useState<string[]>(
    splitString(params.get("expertise") ?? "")
  );
  const [filterTitles, setFilterTitles] = useState<string[]>(
    splitString(params.get("title") ?? "")
  );
  const [filterTargets, setFilterTargets] = useState<string[]>(
    splitString(params.get("name") ?? "")
  );
  const [filterSortby, setFilterSortby] = useState("");
  const [filterStatusTeam, setFilterStatusTeam] = useState<string[]>(
    splitString(params.get("partnerType") ?? "")
  );
  const [filterLanguage, setFilterLanguage] = useState(
    params.get("language") ?? ""
  );
  const [filterDays, setFilterDays] = useState<string[]>(
    splitString(params.get("day") ?? "")
  );
  const [filterExperience, setFilterExperience] = useState(
    params.get("experience") ?? ""
  );

  const [search, setSearch] = useState("");
  const [menuSpecialist, setMenuSpecialist] = useState(-1);
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setLimit] = useState(15);

  // Callback
  const onChangeExpertise = (val: string) => {
    const expertiseIndex = filterExpertises.findIndex((v) => v === val);
    if (expertiseIndex !== -1) {
      filterExpertises.splice(expertiseIndex, 1);
    } else {
      filterExpertises.push(val);
    }

    setFilterExpertises([...filterExpertises]);
  };

  const onChangeStatusTeam = (val: string) => {
    const statusTeamIndex = filterStatusTeam.findIndex((v) => v === val);
    if (statusTeamIndex !== -1) {
      filterStatusTeam.splice(statusTeamIndex, 1);
    } else {
      filterStatusTeam.push(val);
    }

    setFilterStatusTeam([...filterStatusTeam]);
  };

  const onChangeTitle = (val: string) => {
    const titleIndex = filterTitles.findIndex((v) => v === val);
    if (titleIndex !== -1) {
      filterTitles.splice(titleIndex, 1);
    } else {
      filterTitles.push(val);
    }

    setFilterTitles([...filterTitles]);
  };

  const onChangeTargets = (val: string) => {
    const targetIndex = filterTargets.findIndex((v) => v === val);
    if (targetIndex !== -1) {
      filterTargets.splice(targetIndex, 1);
    } else {
      filterTargets.push(val);
    }

    setFilterTargets([...filterTargets]);
  };

  const onChangeDays = (val: string) => {
    const index = filterDays.findIndex((item) => item === val);
    if (index === -1) {
      filterDays.push(val);
    } else {
      filterDays.splice(index, 1);
    }

    setFilterDays([...filterDays]);
  };

  const onFetchSpecialist = async () => {
    setNotFound(false);
    let data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
      targets: filterTargets.join(","),
      sortBy: filterSortby,
      yoeLevel: filterExperience,
      partnerType: filterStatusTeam.join(","),
      keyword: search,
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // Big 3 filter
    setNotFound(true);
    data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
      yoeLevel: filterExperience,
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // Big 2 filter
    data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
      yoeLevel: filterExperience,
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // Big 1 Filter
    data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // No Filter
    return getSpecialists({
      limit: limit,
      page: 1,
    });
  };

  // Query
  const expertises = useQuery("fetchExpertise", () =>
    getExpertises().then((val) => val.data)
  );
  const specialists = useQuery("fetchSpecialist", onFetchSpecialist);
  const titles = useQuery("fetchTitle", () =>
    fetchTitle({ lang: "id" }).then((val) => val.data)
  );
  const targets = useQuery("fetchTarget", () =>
    fetchTargets({ lang: "id" }).then((val) => val.data)
  );

  const pagination = (specialists.data ?? {}).pagination ?? {};
  const isEnableMoreButton =
    (pagination.count ?? 0) > (pagination.limit ?? 0) * pagination.page;

  const isSelectedFilter = () => {
    return (
      filterSortby !== "" ||
      filterLanguage !== "" ||
      filterExperience !== "" ||
      filterDays.length > 0 ||
      filterTitles.length > 0 ||
      filterTargets.length > 0 ||
      filterStatusTeam.length > 0 ||
      filterExpertises.length > 0
    );
  };

  useEffect(() => {
    specialists.refetch();
  }, [limit]);

  useEffect(() => {
    setTimeout(() => {
      specialists.refetch();

      // window.scrollTo({ top: 0, behavior: "smooth" });
      // utils.postMessageScrollTop();
    }, 100);
  }, [
    filterSortby,
    filterExperience,
    filterTitles,
    filterTargets,
    filterStatusTeam,
  ]);

  const onApplyFilter = () => {
    specialists.refetch();

    // window.scrollTo({ behavior: "smooth", top: 0 });
    // utils.postMessageScrollTop();
  };

  const onResetFilter = () => {
    setFilterExpertises([]);
    setFilterTitles([]);
    setFilterTargets([]);
    setFilterStatusTeam([]);
    setFilterSortby("");
    setFilterDays([]);
    setFilterExperience("");
    setSearch("");

    // Wait for change
    setTimeout(() => {
      specialists.refetch();

      // window.scrollTo({ top: 0, behavior: "smooth" });
      // utils.postMessageScrollTop();
    }, 200);
  };

  const onRefetch = () => {
    if (!isEnableMoreButton) {
      return;
    }

    setLimit(limit + 5);
  };
  const FilterMenu = () => {
    return (
      <div className="bg-white rounded-lg">
        <div className="p-6 bg-white border-b border-b-gray-100 flex justify-between">
          <div className="flex items-center">
            <Setting4 variant="Linear"></Setting4>
            <div className="ml-4 font-medium text-xl">Filter</div>
          </div>
          <div className="lg:hidden" onClick={() => setOpenFilter(false)}>
            <Add size={42} variant="Linear" className="rotate-45"></Add>
          </div>
        </div>

        <div className="mx-6 py-5">
          <SearchExpandable title="Urutkan Berdasarkan">
            <div className="ml-2">
              {ListSortby.map((val) => {
                return (
                  <div
                    key={val.value}
                    className={`cursor-pointer py-2 rounded-lg px-3 my-1 ${
                      filterSortby === val.value ? "bg-slate-100" : ""
                    }`}
                    onClick={() => setFilterSortby(val.value)}
                  >
                    {val.title}
                  </div>
                );
              })}
            </div>
          </SearchExpandable>

          <SearchExpandable title="Status Tim">
            {listStatusTeam.map((val: any, i: number) => {
              return (
                <CheckboxItem
                  title={val.title}
                  isActive={filterStatusTeam.includes(val.value)}
                  onChange={() => onChangeStatusTeam(val.value)}
                  id={val.value}
                  key={i}
                ></CheckboxItem>
              );
            })}
          </SearchExpandable>

          <SearchExpandable title="Tipe Psikolog">
            {(titles.data ?? []).map((val: any, i: number) => {
              return (
                <CheckboxItem
                  title={val.title}
                  isActive={filterTitles.includes(val.specialist_title_code)}
                  onChange={() => onChangeTitle(val.specialist_title_code)}
                  id={val.specialist_title_code}
                  key={i}
                ></CheckboxItem>
              );
            })}
          </SearchExpandable>

          <SearchExpandable title="Tipe Klien">
            {(targets.data ?? []).map((val: any, i: number) => {
              return (
                <CheckboxItem
                  title={val.name}
                  isActive={filterTargets.includes(val.target_code)}
                  onChange={() => onChangeTargets(val.target_code)}
                  id={val.target_code}
                  key={i}
                ></CheckboxItem>
              );
            })}
          </SearchExpandable>
        </div>
      </div>
    );
  };

  if (expertises.isLoading || specialists.isLoading) {
    return <SkeletonBooking></SkeletonBooking>;
  }

  return (
    <div>
      <div className="flex justify-between items-center gap-4 mb-6">
        <div className="font-semibold text-3xl hidden lg:block">Psikolog</div>

        <div className="grow lg:grow-0 lg:w-96">
          <InputSearch
            value={search}
            onChange={setSearch}
            onSubmit={specialists.refetch}
          ></InputSearch>
        </div>
        <div
          className="flex items-center bg-white py-3 rounded-lg px-4 lg:hidden"
          onClick={() => setOpenFilter(true)}
        >
          <Setting4 variant="Linear"></Setting4>
          <div className="ml-4 font-medium text-xl">Filter</div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <div
          className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white lg:hidden"
          hidden={!openFilter}
        >
          <FilterMenu></FilterMenu>
        </div>

        <div className="flex-shrink-0 w-80 hidden lg:block">
          <FilterMenu></FilterMenu>
        </div>

        <div className="flex-grow">
          {isSelectedFilter() ? (
            <div className="flex mb-8 items-center gap-4 flex-wrap">
              <div className="font-medium text-neutral-black">
                Filter yang Dipilih :
              </div>
              {filterSortby !== "" ? (
                <BadgeFilter
                  title={
                    ListSortby.filter((val) => val.value === filterSortby)[0]
                      .title
                  }
                  onClose={() => setFilterSortby("")}
                ></BadgeFilter>
              ) : null}

              {filterTitles.length > 0
                ? filterTitles.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={
                          (titles.data ?? []).filter(
                            (val: any) => val.specialist_title_code === item
                          )[0].title
                        }
                        onClose={() =>
                          setFilterTitles(
                            filterTitles.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}

              {filterTargets.length > 0
                ? filterTargets.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={
                          (targets.data ?? []).filter(
                            (val: any) => val.target_code === item
                          )[0].name
                        }
                        onClose={() =>
                          setFilterTargets(
                            filterTargets.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}

              {filterStatusTeam.length > 0
                ? filterStatusTeam.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={
                          listStatusTeam.filter((val) => val.value === item)[0]
                            .title
                        }
                        onClose={() =>
                          setFilterStatusTeam(
                            filterStatusTeam.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}
              {/* {filterExpertises.length > 0
                ? filterExpertises.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={getExpertiseTitle(item)}
                        onClose={() =>
                          setFilterExpertises(
                            filterExpertises.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null} */}

              <div
                className="cursor-pointer text-brand-dark"
                onClick={onResetFilter}
              >
                Atur Ulang Semua
              </div>
            </div>
          ) : null}

          {notFound ? (
            <div className="flex gap-4 bg-neutral-background px-6 py-4 mb-8 rounded-lg">
              <SearchNormal1 size={32} variant="Linear"></SearchNormal1>
              <div>
                <div className="font-medium text-lg">Tidak ada hasil.</div>
                <div className="font-light mt-1">
                  Pencarian Anda tidak menghasilkan hasil yang sama persis, coba{" "}
                  <span
                    className="text-brand-dark cursor-pointer"
                    onClick={onResetFilter}
                  >
                    mengatur ulang filter Anda
                  </span>{" "}
                  untuk melihat hasil lebih lanjut. Berikut beberapa spesialis
                  yang direkomendasikan.
                </div>
              </div>
            </div>
          ) : null}

          {(specialists.data.data ?? []).map((val: any) => {
            return (
              <SpecialistItem
                value={val}
                className="mb-8"
                key={val.member_code}
              ></SpecialistItem>
            );
          })}

          <div className="flex justify-center mt-12">
            <ButtonBlack
              className={`py-3 px-12 ${
                isEnableMoreButton ? "" : "!bg-neutral-300"
              }`}
              onClick={onRefetch}
            >
              Lihat Lebih Banyak
            </ButtonBlack>
          </div>
        </div>
      </div>
    </div>
  );
}
