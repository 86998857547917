/* eslint-disable react-hooks/exhaustive-deps */
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import id from "date-fns/locale/id";
import { ButtonBlue } from "components/Button";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { config } from "helper/config";
import { User, Video } from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  getAvailabilityFormatCall,
  getAvailabilityLocationV2,
  getScheduleMonthlyV2,
  getSchedulesV2,
} from "service/schedule";
import { getSpecialist, getSpecialistRates } from "service/specialist";
import utils from "utils";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { CardLocation } from "components/CardLocation";
import { CardAppointment } from "components/CardAppointment";
import SkeletonPayment from "components/skeleton/SkeletonPayment";

const IN_PERSON_CHILD = "in_person_child";
const IN_PERSON_COUPLE = "in_person_couple";
const IN_PERSON_FAMILY = "in_person_family";
const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
const IN_PERSON_INDIVIDUAL = "in_person_individual";
const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
const ONLINE_CALL_COUPLE = "online_call_couple";
const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
const invalidLocationCode = "LOC---------";
const onlineLocationCode = "";

export default function Booking() {
  const params = useParams();
  const [searchQuery] = useSearchParams();
  const memberCode = params.member_code as string;

  const [calendarDate, setCalendarDate] = useState(new Date());

  const [value, setValue] = useState(new Date());
  const [appointmentType, setAppointmentType] = useState(
    searchQuery.get("appointment_type") ?? IN_PERSON_INDIVIDUAL
  );
  const [locationCode, setLocationCode] = useState(invalidLocationCode);
  const [scheduleDetailCode, setScheduleDetailCode] = useState("");

  const isButtonEnabled = appointmentType.includes("online")
    ? scheduleDetailCode
    : scheduleDetailCode && locationCode != invalidLocationCode;

  const onFetchProfile = () => {
    return () =>
      getSpecialist({ memberCode: memberCode, lang: "id" }).then((val) => {
        return val.data;
      });
  };

  const [schedule, setSchedule] = useState<any>();
  const onFetchSchedule = () => {
    getSchedulesV2({
      memberCode: memberCode,
      status: "active",
      date: moment(value.toISOString()).format("YYYY-MM-DD"),
      formatCall: appointmentType,
      locationCode: locationCode,
    }).then((val) => {
      setSchedule(val.data);
    });
  };

  const queryProfile = useQuery("getSpecialistProfile", onFetchProfile());
  const profile = queryProfile.data;

  const onFetchAvailabilityFormatCall = () => {
    return () =>
      getAvailabilityFormatCall({
        memberCode: memberCode,
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const queryFormatCall = useQuery(
    "getAvailabilityFormatCall",
    onFetchAvailabilityFormatCall()
  );
  const formatCalls = queryFormatCall.data;

  const [scheduleMonthly, setScheduleMonthly] = useState<any>();
  const fetchScheduleMonthly = () => {
    getScheduleMonthlyV2({
      memberCode: memberCode,
      formatCall: appointmentType,
      locationCode: locationCode,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    })
      .then((val) => {
        setScheduleMonthly(val.data);
      })
      .catch((err) => toast.error(err));
  };

  const isDisabled = scheduleDetailCode === "";

  const getPrice = (appointmentType: string) => {
    const price = utils.currencyFormatter(
      profileRates.data.rates.find(
        (_data: { format_call: string }) =>
          _data.format_call === appointmentType
      )?.price
    );
    // console.log(appointmentType + " : " + price);
    return price.replace(/\u00A0/, " ");
  };

  const getPeriod = (period: string) => {
    if (period === "Morning") {
      return "Pagi";
    } else if (period === "Afternoon") {
      return "Siang";
    } else if (period === "Evening") {
      return "Sore";
    } else {
      return "Malam";
    }
  };

  const onFetchRates = (memberCode: string) => {
    return () =>
      getSpecialistRates({
        memberCode: memberCode,
        lang: "id",
      }).then((val) => {
        return val.data;
      });
  };

  const profileRates = useQuery("getSpecialistRates", onFetchRates(memberCode));

  const onFetchLocation = (memberCode: string) => {
    return () =>
      getAvailabilityLocationV2({
        memberCode: memberCode,
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const scheduleLocation = useQuery("getLocation", onFetchLocation(memberCode));

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (
      appointmentType !== "" &&
      locationCode !== invalidLocationCode &&
      showCalendar
    )
      fetchScheduleMonthly();
    if (appointmentType.includes("online") && showCalendar)
      fetchScheduleMonthly();
  }, [calendarDate, appointmentType, locationCode]);

  useEffect(() => {
    queryFormatCall.refetch();
  }, [calendarDate]);

  useEffect(() => {
    setScheduleDetailCode("");
    if (
      appointmentType !== "" &&
      locationCode !== invalidLocationCode &&
      showCalendar
    )
      onFetchSchedule();
    if (appointmentType.includes("online") && showCalendar) onFetchSchedule();
  }, [value]);

  if (queryProfile.isLoading) {
    return <SkeletonPayment></SkeletonPayment>;
  }

  return (
    <div>
      <Stepper step={1}></Stepper>
      <div className="flex flex-col lg:flex-row flex-wrap">
        <div className="p-8 bg-white rounded-xl lg:basis-7/12">
          <Title>Pilih Tipe</Title>
          {formatCalls !== undefined ? (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Individual)"
                price={getPrice("in_person_individual")}
                isHidden={
                  !formatCalls.in_person_individual ||
                  getPrice("in_person_individual") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_INDIVIDUAL}
                onClick={() => {
                  setAppointmentType(IN_PERSON_INDIVIDUAL);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Couple)"
                price={getPrice("in_person_couple")}
                isHidden={
                  !formatCalls.in_person_couple ||
                  getPrice("in_person_couple") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_COUPLE}
                onClick={() => {
                  setAppointmentType(IN_PERSON_COUPLE);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Family)"
                price={getPrice("in_person_family")}
                isHidden={
                  !formatCalls.in_person_family ||
                  getPrice("in_person_family") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_FAMILY}
                onClick={() => {
                  setAppointmentType(IN_PERSON_FAMILY);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Child)"
                price={getPrice("in_person_child")}
                isHidden={
                  !formatCalls.in_person_child ||
                  getPrice("in_person_child") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_CHILD}
                onClick={() => {
                  setAppointmentType(IN_PERSON_CHILD);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Hypnotherapy)"
                price={getPrice("in_person_hypnotherapy")}
                isHidden={
                  !formatCalls.in_person_hypnotherapy ||
                  getPrice("in_person_hypnotherapy") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_HYPNOTHERAPY}
                onClick={() => {
                  setAppointmentType(IN_PERSON_HYPNOTHERAPY);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (Speech Therapy)"
                price={getPrice("in_person_speech_therapy")}
                isHidden={
                  !formatCalls.in_person_speech_therapy ||
                  getPrice("in_person_speech_therapy") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_SPEECH_THERAPY}
                onClick={() => {
                  setAppointmentType(IN_PERSON_SPEECH_THERAPY);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<User className="w-5 md:w-8" variant="Bold" />}
                title="Face to face (SI Therapy)"
                price={getPrice("in_person_sensory_integration_therapy")}
                isHidden={
                  !formatCalls.in_person_sensory_integration_therapy ||
                  getPrice("in_person_sensory_integration_therapy") === "Rp 0"
                }
                choosed={appointmentType === IN_PERSON_SI_THERAPY}
                onClick={() => {
                  setAppointmentType(IN_PERSON_SI_THERAPY);
                  setLocationCode(invalidLocationCode);
                  setShowCalendar(false);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<Video className="w-5 md:w-8" variant="Bold" />}
                title="Online Call (Individual)"
                price={getPrice("online_call_individual")}
                isHidden={
                  !formatCalls.online_call_individual ||
                  getPrice("online_call_individual") === "Rp 0"
                }
                choosed={appointmentType === ONLINE_CALL_INDIVIDUAL}
                onClick={() => {
                  setLocationCode(onlineLocationCode);
                  setAppointmentType(ONLINE_CALL_INDIVIDUAL);
                  setCalendarDate(new Date());
                  setValue(new Date());
                  setSchedule([]);
                  setShowCalendar(true);
                }}
              />
              <CardAppointment
                icon={<Video className="w-5 md:w-8" variant="Bold" />}
                title="Online Call (Couple)"
                price={getPrice("online_call_couple")}
                isHidden={
                  !formatCalls.online_call_couple ||
                  getPrice("online_call_couple") === "Rp 0"
                }
                choosed={appointmentType === ONLINE_CALL_COUPLE}
                onClick={() => {
                  setLocationCode(onlineLocationCode);
                  setAppointmentType(ONLINE_CALL_COUPLE);
                  setCalendarDate(new Date());
                  setValue(new Date());
                  setSchedule([]);
                  setShowCalendar(true);
                }}
              />
            </div>
          ) : (
            <div></div>
          )}

          <Title>Pilih Lokasi</Title>
          <div>
            {appointmentType.includes("online") ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                <CardLocation
                  title="Online"
                  isOnline={true}
                  choosed={true}
                  onClick={() => setLocationCode(onlineLocationCode)}
                />
              </div>
            ) : scheduleLocation.data ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {scheduleLocation.data.locations[appointmentType]?.map(
                  (val: any) => {
                    return (
                      <CardLocation
                        key={val.location_code}
                        title={val.name}
                        choosed={locationCode === val.location_code}
                        onClick={() => {
                          setCalendarDate(new Date());
                          setValue(new Date());
                          setLocationCode(val.location_code);
                          setSchedule([]);
                          setShowCalendar(true);
                        }}
                      />
                    );
                  }
                )}
              </div>
            ) : null}
          </div>

          <Title>Pilih Tanggal</Title>
          {showCalendar ? (
            <LocalizationProvider
              adapterLocale={id}
              dateAdapter={AdapterDateFns}
            >
              <DateCalendar
                value={value}
                onMonthChange={(val) => setCalendarDate(val as Date)}
                onYearChange={(val) => setCalendarDate(val as Date)}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{ day: ServerDay } as any}
                sx={{
                  width: 1,
                  mb: 3,
                  "& .MuiPickersFadeTransitionGroup-root ": {
                    width: 1,
                    display: "",
                  },
                  "& .MuiPickersCalendarHeader-root  ": {
                    padding: 0,
                    margin: 0,
                  },
                  "& .MuiDayCalendar-header ": {
                    width: 1,
                    justifyContent: "space-between",
                  },
                  "& .MuiPickersSlideTransition-root ": {
                    width: 1,
                    display: "",
                  },
                  "& .MuiDayCalendar-weekContainer ": {
                    width: 1,
                    justifyContent: "space-between",
                  },
                }}
                slotProps={{
                  day: {
                    availables: scheduleMonthly,
                    onChange: (val: string) => {
                      console.log(val);
                      setValue(new Date(val));
                    },
                  } as any,
                }}
              />
            </LocalizationProvider>
          ) : (
            <p className="mb-4 text-sm">
              Pilih Tipe Sesi dan Lokasi Terlebih dahulu
            </p>
          )}

          <Title>Pilih Jam</Title>
          {(schedule ?? []).map((val: any, i: number) => {
            return (
              <div className="my-6" key={i}>
                <div className="mb-4 text-neutral-secondary">
                  {getPeriod(val.period)}
                </div>
                <div className="grid grid-flow-row grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                  {val.details.map((item: any, j: number) => {
                    return (
                      <div
                        key={`${i}-${j}`}
                        className={`py-2 text-center px-8 border rounded-lg cursor-pointer ${
                          item.schedule_detail_code === scheduleDetailCode
                            ? "border-black-app bg-blue-background"
                            : ""
                        }`}
                        onClick={() =>
                          setScheduleDetailCode(item.schedule_detail_code)
                        }
                      >
                        {moment(item.time_from, "HH:mm").format("hh:mm A")}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          <Link
            to={
              isDisabled
                ? "#"
                : `/booking/${memberCode}/${scheduleDetailCode}?appointment_type=${appointmentType}&location=${locationCode}`
            }
          >
            <ButtonBlue
              className={`w-full py-3 px-8 text-center ${
                isButtonEnabled ? "" : "bg-neutral-200"
              }`}
            >
              Selanjutnya
            </ButtonBlue>
          </Link>
        </div>
        <div className="order-first lg:order-last lg:basis-5/12 lg:pl-10">
          <div className="p-8 bg-white rounded-xl">
            <Title>Detail Pemesanan</Title>
            <Link
              to={`${config.HOME_URL}/booking/profile/${memberCode}`}
              target="_top"
            >
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:items-center md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16 object-cover"
                    src={`${config.AWS_URL_IMAGES}${profile.profile_url}`}
                    alt=""
                  />
                </div>
                <div>
                  <div className="text-xl font-medium">{profile.username}</div>
                  <div className="text-neutral-secondary">
                    {profile.specialist_title}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function ServerDay(
  props: PickersDayProps<Date> & {
    availables?: any[];
    onChange: (val: Date) => void;
  }
) {
  const { availables = [], day, outsideCurrentMonth, ...other } = props;

  const indexAvailable = availables.findIndex(
    (val) => val.date === dayjs(day).format("YYYY-MM-DD")
  );
  const isSelected = !props.outsideCurrentMonth && indexAvailable !== -1;

  return (
    <div
      className={`${
        isSelected ? "w-10 h-10 bg-blue-background rounded-full" : ""
      }`}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={!isSelected}
        onClick={() => props.onChange(availables[indexAvailable].date)}
      />
    </div>
  );
}
