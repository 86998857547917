import api from "./api";

export const fetchTitle = (params: { lang: string }) => {
  return api
    .get("/v2/specialist/title", {
      params: {
        is_active: true,
        lang: params.lang,
      },
    })
    .then((val) => val.data);
};
