import { Loading } from "components/Loading";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { onJoinGMeet } from "helper";
import { config } from "helper/config";
import { TickCircle } from "iconsax-react";
import moment from "moment";
import { useState } from "react";
import ApiCalendar from "react-google-calendar-api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateReservation } from "service/reservation";
import utils from "utils";

const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
const ONLINE_CALL_COUPLE = "online_call_couple";

export default function ConfirmedPaymentOvertime(props: {
  reservation: any;
  overtime: any;
}) {
  const reservation = props.reservation;
  const overtime = props.overtime;
  const total = overtime.price;

  return (
    <div>
      <Stepper step={3}></Stepper>
      <div className="max-w-2xl mx-auto">
        <div className="p-8 bg-white rounded-xl">
          <div className="max-w-lg mx-auto">
            <div className="text-center">
              <Title>You have successfully pay overtime</Title>
            </div>
            <div className="flex justify-center my-8">
              <div className="bg-blue-background p-6 rounded-full">
                <TickCircle
                  className="text-brand-dark"
                  variant="Bold"
                  size={46}
                ></TickCircle>
              </div>
            </div>
            <Title>Detail Pemesanan</Title>
            <Link
              to={`${config.HOME_URL}/booking/${reservation.psycholog_code}`}
              target="_top"
            >
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16"
                    src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <div className="text-xl font-medium mb-1">
                      {reservation.psycholog_username}
                    </div>
                    <div className="text-neutral-secondary">
                      {reservation.psycholog_title}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Janji Temu
                    </div>
                    <div className="text-neutral-800">
                      Overtime {overtime.duration} minutes
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Lokasi
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === ONLINE_CALL_INDIVIDUAL ||
                      reservation.format_call === ONLINE_CALL_COUPLE
                        ? `Online`
                        : `${reservation.location_detail.name} - ${reservation.location_detail.address}`}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Tanggal
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.date).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Waktu
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            <div className="flex justify-between text-xl font-medium text-black-app mt-6">
              <div>Total</div>
              <div>{utils.currencyFormatter(total)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
