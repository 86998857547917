import { ButtonBlack, ButtonBorder } from "components/Button";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { config } from "helper/config";
import { ArrowDown2, ArrowUp2, Copy } from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { simPaymentCallback } from "service/payment";
import utils from "utils";

const IN_PERSON_CHILD = "in_person_child";
const IN_PERSON_COUPLE = "in_person_couple";
const IN_PERSON_FAMILY = "in_person_family";
const IN_PERSON_INDIVIDUAL = "in_person_individual";
const IN_PERSON_HYPNOTHERAPY = "in_person_hypnotherapy";
const IN_PERSON_SI_THERAPY = "in_person_sensory_integration_therapy";
const IN_PERSON_SPEECH_THERAPY = "in_person_speech_therapy";
const ONLINE_CALL_INDIVIDUAL = "online_call_individual";
const ONLINE_CALL_COUPLE = "online_call_couple";

export default function WaitingPayment(props: {
  onCancelPayment: () => void;
  onChangePayment: () => void;
  reservation: any;
}) {
  const reservation = props.reservation;
  const total = reservation.payment_gateaway_data.amount;

  const gatewaydata = reservation.payment_gateaway_data;
  const paymentMethod = gatewaydata.method;
  const paymentResponseType = gatewaydata.raw_response.type;

  const isVA = () => paymentResponseType === "VA";
  const isQRIS = () => paymentResponseType === "QRIS";
  //   const isEWallet = () => paymentResponseType === "EWALLET";

  const paymentType = isVA()
    ? "Virtual Account"
    : isQRIS()
    ? "QRIS"
    : "E-Wallet";

  let checkoutURL = "";
  let qrString: string = "";
  let accountNumber: any;
  let instructionATM: any;
  let instructionMobileApp: any;

  if (isVA()) {
    const instruction =
      reservation.payment_gateaway_data.raw_response.response
        .payment_instruction;

    accountNumber = gatewaydata.raw_response.response.account_number;
    instructionATM = instruction.en !== undefined ? instruction.en.atm : null;
    instructionMobileApp =
      instruction.en !== undefined ? instruction.en.mobile_app : null;
    checkoutURL = gatewaydata.raw_response.response.checkout_url;
  }

  if (isQRIS()) {
    qrString = gatewaydata.raw_response.response.qr_string;
  }

  const onCopy = () => {
    if (isVA()) {
      navigator.clipboard.writeText(accountNumber);
      return toast.success("bank number already copied");
    }
  };

  const isOnline =
    reservation.format_call === ONLINE_CALL_INDIVIDUAL ||
    reservation.format_call === ONLINE_CALL_COUPLE;

  return (
    <div>
      <Stepper step={3}></Stepper>

      <div className="flex flex-col lg:flex-row">
        <div className="lg:basis-7/12 flex flex-col lg:gap-12">
          <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
            <div className="font-medium text-2xl text-black-app text-center">
              Complete Your Payment In
            </div>
            <CountDown date={new Date(reservation.expired_date)}></CountDown>
            <div className="border px-6 rounded-lg">
              <div className="flex flex-col justify-between border-b py-6 gap-2 md:flex-row">
                <div className="text-neutral-secondary font-medium">
                  Payment Method
                </div>
                <div>
                  {isQRIS()
                    ? `${paymentMethod}`
                    : `${paymentMethod} ${paymentType}`}
                </div>
              </div>

              {isQRIS() === false ? (
                <div className="flex flex-col justify-between border-b py-6 gap-2 md:flex-row">
                  <div className="text-neutral-secondary font-medium">
                    {`${isVA() ? `${paymentType} Number` : "Link"}`}{" "}
                  </div>
                  <div className="flex gap-2 cursor-pointer" onClick={onCopy}>
                    {isVA() ? (
                      <>
                        <div>{accountNumber}</div>
                        <div className="bg-blue-background p-1 rounded-full">
                          <Copy variant="Bold" size={16}></Copy>
                        </div>
                      </>
                    ) : (
                      <a
                        className="text-brand-dark"
                        href={checkoutURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link Payment
                      </a>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="flex flex-col justify-between py-6 gap-2 md:flex-row">
                <div className="text-neutral-secondary font-medium">
                  Payment Total
                </div>
                <div>{utils.currencyFormatter(total)}</div>
              </div>
            </div>
            <div className="text-center text-lg">
              <div className="text-neutral-secondary">
                Already made a payment?
              </div>
              <div
                className="text-brand-dark font-medium cursor-pointer"
                onClick={() => window.location.reload()}
              >
                Update payment status
              </div>
              {process.env.REACT_APP_STAGE !== "production" && (
                <div className="my-2">
                  <div
                    className="inline-block bg-green-600 text-white p-2 mx-2 text-sm font-medium cursor-pointer rounded-lg"
                    onClick={() => {
                      simPaymentCallback({
                        status: "completed",
                        payment_id: gatewaydata.payment_code,
                        order_id: gatewaydata.order_code,
                        amount: gatewaydata.amount,
                        payment_method: gatewaydata.method,
                      });
                    }}
                  >
                    Sukses
                  </div>
                  <div
                    className="inline-block bg-yellow-600 text-white p-2 mx-2 text-sm font-medium cursor-pointer rounded-lg"
                    onClick={() => {
                      simPaymentCallback({
                        status: "cancelled",
                        payment_id: gatewaydata.payment_code,
                        order_id: gatewaydata.order_code,
                        amount: gatewaydata.amount,
                        payment_method: gatewaydata.method,
                      });
                    }}
                  >
                    Batal
                  </div>
                  <div
                    className="inline-block bg-red-600  text-white p-2 mx-2 text-sm font-medium cursor-pointer rounded-lg"
                    onClick={() => {
                      simPaymentCallback({
                        status: "failed",
                        payment_id: gatewaydata.payment_code,
                        order_id: gatewaydata.order_code,
                        amount: gatewaydata.amount,
                        payment_method: gatewaydata.method,
                      });
                    }}
                  >
                    Gagal
                  </div>
                </div>
              )}
            </div>

            {isQRIS() ? (
              <div className="mx-auto">
                <img className="w-64 md:w-80 lg:w-96" src={qrString} alt="" />
              </div>
            ) : null}

            {isQRIS() ? (
              <>
                <div className="font-medium text-xl text-black-app">
                  Payment Instructions
                </div>
                <ol className="list-decimal ml-4">
                  <li>
                    Scan/screenshot kode QR dengan mobile banking atau aplikasi
                    pembayaran yang mendukung QRIS{" "}
                  </li>
                  <li>Periksa detail transaksi pada aplikasi</li>
                  <li>
                    Klik tombol <span className="font-semibold">Bayar</span>
                  </li>
                  <li>
                    Setelah pembayaran selesai, kamu akan mendapat notifikasi
                    pembayaran
                  </li>
                </ol>
              </>
            ) : null}

            {isVA() &&
            instructionATM !== null &&
            instructionMobileApp !== null ? (
              <>
                <div className="font-medium text-xl text-black-app">
                  Payment Instructions
                </div>
                <Instructions
                  heading={instructionATM.heading}
                  content={instructionATM.instruction_text}
                ></Instructions>
                <Instructions
                  heading={instructionMobileApp.heading}
                  content={instructionMobileApp.instruction_text}
                ></Instructions>
              </>
            ) : null}

            <div className="flex justify-end gap-4">
              <div onClick={props.onCancelPayment}>
                <ButtonBorder>
                  <div className="font-medium">Cancel booking</div>
                </ButtonBorder>
              </div>
              <ButtonBlack
                className="flex items-center px-5 font-medium justify-center text-center"
                onClick={props.onChangePayment}
              >
                Change payment method
              </ButtonBlack>
            </div>
          </div>
        </div>

        <div className="order-first lg:order-last lg:basis-5/12 lg:pl-10">
          <div className="p-8 bg-white lg:rounded-xl">
            <Title>Detail Pemesanan</Title>
            <Link
              to={`${config.HOME_URL}/booking/${reservation.psycholog_code}`}
              target="_top"
            >
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16"
                    src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <div className="text-xl font-medium">
                      {reservation.psycholog_username}
                    </div>
                    <div className="text-neutral-secondary">
                      {reservation.specialist_title}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Janji Temu
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === IN_PERSON_INDIVIDUAL
                        ? "Face to face (Individual)"
                        : reservation.format_call === IN_PERSON_COUPLE
                        ? "Face to face (Couple)"
                        : reservation.format_call === IN_PERSON_FAMILY
                        ? "Face to face (Family)"
                        : reservation.format_call === IN_PERSON_CHILD
                        ? "Face to face (Child)"
                        : reservation.format_call === IN_PERSON_HYPNOTHERAPY
                        ? "Face to face (Hypnotherapy)"
                        : reservation.format_call === IN_PERSON_SI_THERAPY
                        ? "Face to face (SI Therapy)"
                        : reservation.format_call === IN_PERSON_SPEECH_THERAPY
                        ? "Face to face (Speech Therapy)"
                        : reservation.format_call === ONLINE_CALL_INDIVIDUAL
                        ? "Online Call (Individual)"
                        : "Online Call (Couple)"}{" "}
                      60 menit
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Lokasi
                    </div>
                    <div className="text-neutral-800">
                      {isOnline
                        ? `Online`
                        : `${reservation.location_detail.name} - ${reservation.location_detail.address}`}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Tanggal
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.date).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      Waktu
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
                    </div>
                  </div>

                  {reservation.room_detail && (
                    <div>
                      <div className="font-medium text-neutral-secondary">
                        Ruang
                      </div>
                      <div className="text-neutral-800">
                        {reservation.room_detail.room_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Link>

            <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Subtotal</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.amount)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Promo</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.promo_used)}
                </div>
              </div>
            </div>

            <div className="flex justify-between text-xl font-medium text-black-app mt-6">
              <div>Total</div>
              <div>{utils.currencyFormatter(total)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CountDown(props: { date: Date }) {
  const [miliseconds, setMiliseconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const milis = props.date.getTime() - new Date().getTime();

      if (milis < 0) {
        clearInterval(interval);
      } else {
        setMiliseconds(milis);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [props.date]);

  const seconds = Math.floor(miliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hour = Math.floor(minutes / 60);
  const secondsInTime = seconds % 60;
  const minutesInTime = minutes % 60;

  const ItemTimer = (props: { value: number }) => {
    return (
      <div
        className={`text-brand-dark rounded-md w-24 h-24 flex items-center justify-center
                    ${miliseconds > 0 ? "bg-blue-background" : "bg-red-app/20"}
                `}
      >
        <div>{utils.timeZeroLeading(props.value)}</div>
      </div>
    );
  };

  return (
    <>
      <div className="flex text-4xl justify-center items-center">
        {hour > 0 ? (
          <>
            <ItemTimer value={hour}></ItemTimer>
            <div className="font-medium mx-2">:</div>
          </>
        ) : null}

        <ItemTimer value={minutesInTime}></ItemTimer>
        <div className="font-medium mx-2">:</div>
        <ItemTimer value={secondsInTime}></ItemTimer>
      </div>
      <div className="font-medium text-lg text-center">
        <p>
          Before{" "}
          {moment(props.date.toString()).format("dddd, DD MMM yyyy HH:mm")}
        </p>
      </div>
    </>
  );
}

function Instructions(props: { heading: string; content: string }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="overflow-hidden border rounded-md">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="font-medium">{props.heading}</div>
        <div>
          {isOpen ? (
            <ArrowUp2 size={20} variant="Linear"></ArrowUp2>
          ) : (
            <ArrowDown2 size={20} variant="Linear"></ArrowDown2>
          )}
        </div>
      </div>

      <div className={`overflow-hidden ${isOpen ? "" : "h-0"}`}>
        <div className="pb-4 reset-style ">
          <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
        </div>
      </div>
    </div>
  );
}
