import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";

export default function ShowHidePassword(props: {
  name?: string;
  value?: string;
  defaultValue?: string;
  placeholder: string;
  onChange: (_: string) => void;
}) {
  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  return (
    <div className="relative items-center w-full p-0 text-gray-900 rounded-lg bg-white border-0 border-neutral-100 focus:border-blue-200">
      <input
        type={!isVisible ? "password" : "text"}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        required
        onChange={(val) => props.onChange(val.target.value)}
        className="w-full p-4 text-gray-900 rounded-lg bg-white border border-neutral-300 focus:border-blue-200"
        maxLength={12}
        minLength={8}
      />
      <span className="absolute right-4 top-4" onClick={toggle}>
        {isVisible ? (
          <Eye size="24" color="#767E8C" />
        ) : (
          <EyeSlash size="24" color="#767E8C" />
        )}
      </span>
    </div>
  );
}
