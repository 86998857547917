export function ButtonBlack(props: {
  children: any;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div
      className={`mb-4 sm:mb-0 rounded-full select-none leading-6 text-sm text-white bg-black-app cursor-pointer ${
        props.className ?? ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function ButtonBorder(props: { children: any; onClick?: () => void }) {
  return (
    <div
      className="mb-4 sm:mb-0 px-6 py-3 select-none rounded-full leading-6 text-sm text-black text-center bg-white border cursor-pointer"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function ButtonGold(props: {
  children: any;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div
      className={`mb-4 sm:mb-0 rounded-full select-none leading-6 text-sm text-white text-center bg-layout-gold cursor-pointer ${
        props.className ?? ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function ButtonBlue(props: {
  children: any;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div
      className={`mb-4 sm:mb-0 rounded-full select-none leading-6 text-sm text-center text-white bg-custom-blue cursor-pointer ${
        props.className ?? ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
